import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import Images from "../../../helpers/images";
import { Link } from "react-router-dom";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const Product = () => {
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }
  return (
    <>
      <CommonMeta
        title="Sustainable Product Approach by Poddar Pigments Ltd"
        description="Poddar Pigments Ltd's sustainable product approach: eco-friendly materials, longevity, and reduced environmental footprint for responsible consumption"
        keywords="Sustainable Product , masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Product"
        type="article"
      />
      <Header />
      <div className="top-banner-image sustainability-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1> {t("Sustainability")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper sustainbility-tab">
              <div className="card-box-wrapper">
                <Link to="/sustainability/process">
                  <div className="card-box">
                    <div className="name">
                      <h4> {t("Process")}</h4>
                      <div className="icon">
                        <i className="icon-next_arrow"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/sustainability/people">
                  <div className="card-box">
                    <div className="name">
                      <h4> {t("People")}</h4>
                      <div className="icon">
                        <i className="icon-next_arrow"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("Product")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sustainable-wrap">
        <Container>
          <Row className="heading-text">
            <h2> {t("Product Sustainability")}</h2>
            <p> {t("As a leading masterbatch manufacturing company in India exporting across the globe, product sustainability is at the core of our commitment at Poddar Pigments Limited. We prioritize environmental responsibility and longevity in the design, manufacturing, and lifecycle of our products. Our sustainable approach focuses on reducing resource consumption, minimizing waste, and ensuring that our products are built to last. We use eco-friendly materials and manufacturing processes with a reduced environmental footprint. Our products are designed for easy repair, reuse, or recycling, extending their lifecycle and reducing overall waste.")}
            </p>
          </Row>

          <Row className="align-items-center heading-text-wrapp mobile-view sust-product-sec">
            <Col lg={5}>
              <div className="image-box1">
                <img
                  loading="lazy"
                  src={Images.dying}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={7}>
              <div className="heading-text">
                <h2> {t("Dope Dyeing Innovation for Environmental Responsibility")}</h2>
                <p> {t("Our Dope Dyeing process is a testament to our commitment to responsible energy practices. It's an environmentally friendly, waterless innovation that competes effectively with wet dyeing process that is notorious as the most polluted process in the industry. With this innovation, we're minimizing our environmental impact and contributing to a greener, more sustainable future.")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default Product;
