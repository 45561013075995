import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation,} from 'react-i18next';

const StickyHeaderActions = () => {
   // If translation.json file in the relevent locale folder
  // const { t } = useTranslation('common'); // If common.json file in the relevent locale folder
  // const { t } = useTranslation(['common', 'header']); // If common.json and header.json file in the relevent locale folder
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  const location = useLocation();
  const currentPath = location?.pathname;

  const [activeLinkName, setActiveLinkName] = useState("#home");

  useEffect(() => {
    if (currentPath === "/") {
      setActiveLinkName(location?.hash);
    } else {
      setActiveLinkName(currentPath);
    }
  }, [location]);

  return (
    <div className="navbars">
        <div className="language-section">
        </div>
      <ul>
        <li>
          <HashLink
            className={activeLinkName === "/home" ? "active" : ""}
            smooth={true}
            to="/#home"
          >
            {t("navbar.home")}
          </HashLink>
        </li>
        <li>
          <HashLink
            className={activeLinkName === "/AboutUs" ? "active" : ""}
            smooth={true}
            to="/#AboutUs"
          >
            {t("navbar.about")}
          </HashLink>
        </li>
        <li>
          <HashLink
            className={activeLinkName === "/Products" ? "active" : ""}
            smooth={true}
            to="/#Products"
          >
            {t("navbar.products")}
          </HashLink>
        </li>
        <li>
          <HashLink
            className={activeLinkName === "/Services" ? "active" : ""}
            smooth={true}
            to="/#Services"
          >
            {t("navbar.services")}
          </HashLink>
        </li>
        <li>
          <HashLink
            className={activeLinkName === "/sustainability" ? "active" : ""}
            smooth={true}
            to="/#Sustainability"
          >
            {t("navbar.sustainability")}
          </HashLink>
        </li>
        {/* <li><Link to='/sustainability' className={ activeLinkName === '/sustainability' ? 'active' :'' }>Sustainability </Link></li> */}
        <li>
          <Link
            to="/investor-relations"
            className={activeLinkName === "/investor-relations" ? "active" : ""}
          >
            {t("navbar.investor")}
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            className={activeLinkName === "/contact-us" ? "active" : ""}
          >
            {t("navbar.contact")}
          </Link>
        </li>
        {/* <div className="customer-login-btn"><Link className="customer-login animated-button" to='/'>Customer Login </Link></div> */}
      </ul>
    </div>
  );
};
export default StickyHeaderActions;
