import { Accordion, Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getReportDetail as getReportDetailApi } from "../../../helpers/api";
import config from "../../../config";
import InlineLoader from "../../comman/InlineLoader";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const InvestorRelationsDetails = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState([])

  useEffect(() => {
    getReportDetails();
  }, []);


  const getReportDetails = async () => {
    try {
      const { data } = await getReportDetailApi(decodeURI(params.type), decodeURI(params.report))
      setReport(data.result)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const getExtension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }
      
  // for lang change
  const { t } = useTranslation();

  
  return (
    <>
     <CommonMeta
        title="Poddar Pigments Ltd: Decade of Growth, Financials, CSR, & Morea"
        description="Poddar Pigments Ltd: Transparent Financials, CSR, Shareholder Info, and Stock Exchange Compliance – Your Trusted Investor Resource"
        keywords="masterbatch india, masterbatches manufacturer in india, color masterbatch"
        name="Investor Relations"
        type="article"
      />
      <Header /><div className="top-banner-image investor-relations-bg"></div>

      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb investorRelations">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item ">
                    <Link to="/investor-relations">{t("Investor Relations")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/investor-relations"> {t(`${params?.type}`)}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t(`${params?.report}`)}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="investor-relations-details-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="">
                <h2> {t(`${report?.title}`)}</h2>
              </div>
              <Accordion className="investor-accordian" defaultActiveKey='tabs-0'>

                <InlineLoader loading={loading} />
                {
                  !loading && [...report?.tabs]?.reverse().map((item, i) => (
                    <Accordion.Item eventKey={`tabs-${i}`} key={`tabs-${i}`}>
                      <Accordion.Header> {item.tabTitle}</Accordion.Header>
                      <Accordion.Body>
                        <ul className="detailBox">
                          {[...item?.innerTabs]?.reverse().map((item, j) => (
                            <li key={`detailBox-${i}-${j}`}>
                              {['WEBM', 'MPG', 'OGG', 'MP4', 'M4V', 'M4P', 'AVI', 'MOV', 'FLV'].includes(getExtension(`${config.INVESTOR_RELATION_IMG_URL}/${item?.innerTabFile}`).toUpperCase()) && (
                                <video width="450" height="240" controls muted>
                                  <source src={`${config.INVESTOR_RELATION_IMG_URL}/${item?.innerTabFile}`} type={`video/${getExtension(`${config.INVESTOR_RELATION_IMG_URL}/${item?.innerTabFile}`)}`} />
                                  <source src="movie.ogg" type="video/ogg" />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                              {!['WEBM', 'MPG', 'OGG', 'MP4', 'M4V', 'M4P', 'AVI', 'MOV', 'FLV'].includes(getExtension(`${config.INVESTOR_RELATION_IMG_URL}/${item?.innerTabFile}`).toUpperCase()) && (
                                <a href={`${config.INVESTOR_RELATION_IMG_URL}/${item?.innerTabFile}`} target="blank">
                                  <div >
                                    <p>{item.innerTabTitle}<i className="icon-file_download"></i></p>
                                  </div>
                                </a>
                              )}
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>

                    </Accordion.Item>
                  ))}
              </Accordion>


            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default InvestorRelationsDetails;
