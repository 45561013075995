import { APICore } from './apiCore';
const api = new APICore();

// experienceCenterList
function experienceCenterList(filters) {
    const queryParams = new URLSearchParams(filters).toString()
    const baseUrl = `/experience-center?${queryParams}`;
    return api.get(`${baseUrl}`);
}

//digitalShadeCardList
function digitalShadeCardList(type) {
    const baseUrl = `/digital-shade-card/${type}`;
    return api.get(`${baseUrl}`);
}

//digitalShadeCardList
function privateDigitalShadeCard(id) {
    const baseUrl = `/private-digital-shade-card/${id}`;
    return api.get(`${baseUrl}`);
}

//digitalShadeCardList
function unlockPrivateDigitalShadeCard(id, params) {
    const baseUrl = `/private-digital-shade-card/unlock/${id}`;
    return api.create(`${baseUrl}`, params);
}

//digitalShadeCardList
function getPrivateDigitalShadeCardImage(id, params) {
    const baseUrl = `/private-digital-shade-card/image/${id}`;
    return api.fetchArrayBufferPost(`${baseUrl}`, params);
}

//jobs
function getJobTitles(params) {
    const baseUrl = `/job-titles`;
    return api.get(`${baseUrl}`, params);
}

function applyForJob(params) {
    const baseUrl = `/apply-for-job`;
    return api.create(`${baseUrl}`, params);
}

function uploadFile(params) {
    const baseUrl = `/apply-for-job/upload`;
    return api.createWithFile(`${baseUrl}`, params);
};

function getReports() {
    const baseUrl = `/investor-relations`;
    return api.get(`${baseUrl}`);
};

function getReportDetail(type, title) {
    const baseUrl = `/investor-relations/${type}/${title}`;
    return api.get(`${baseUrl}`);
};

function contactUs(params) {
    const baseUrl = `/contact-us`;
    return api.create(`${baseUrl}`, params);
};

function chatForm(params) {
    const baseUrl = `/chat-form-submit`;
    return api.create(`${baseUrl}`, params);
};

function saveSteps(id, params) {
    const baseUrl = `/chat-form-submit/${id}`;
    return api.update(`${baseUrl}`, params);
};

function chatData() {
    const baseUrl = `/chat-form-submit`;
    return api.get(`${baseUrl}`);
};

function createYourOwn(params) {
    const baseUrl = `/create-your-own`;
    return api.create(`${baseUrl}`, params);
};

function newsAndEventList() {
    const baseUrl = `/news-and-events`;
    return api.get(`${baseUrl}`);
};

function newsAndEventDetails(title) {
    const baseUrl = `/news-and-events/details/${title}`;
    return api.get(`${baseUrl}`);
};

function getStockPrice(title) {
    const baseUrl = `/stock/get`;
    return api.get(`${baseUrl}`);
};

function getTestimonials(params) {
    const baseUrl = `/testimonials`;
    return api.get(`${baseUrl}`, params);
}


export {
    experienceCenterList,
    digitalShadeCardList,
    privateDigitalShadeCard,
    unlockPrivateDigitalShadeCard,
    getPrivateDigitalShadeCardImage,
    getJobTitles,
    applyForJob,
    uploadFile,
    getReports,
    getReportDetail,
    contactUs,
    chatForm,
    saveSteps,
    chatData,
    createYourOwn,
    newsAndEventList,
    newsAndEventDetails,
    getStockPrice,
    getTestimonials,
};
