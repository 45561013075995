import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const CustomPagination = ({ tableProps, handleChangePage }) => {
        /**
     * pagination count , index
     */
    const [pageCount, setPageCount] = useState(tableProps.pageCount);
    const [pageIndex, setPageIndex] = useState(tableProps.state.pageIndex);

    useEffect(() => {
        setPageCount(tableProps.pageCount);
        setPageIndex(tableProps.state.pageIndex);
    }, [tableProps.pageCount, tableProps.state.pageIndex]);

    /**
     * get filter pages
     */
    const filterPages = useCallback(
        (visiblePages, totalPages) => {
            return visiblePages.filter((page) => page <= pageCount);
        },
        [pageCount]
    );

    /**
     * handle visible pages
     */
    const getVisiblePages = useCallback(
        (page, total) => {
            if (total < 7) {
                return filterPages([1, 2, 3, 4, 5, 6], total);
            } else {
                if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                    return [1, page - 1, page, page + 1, total];
                } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                    return [1, total - 3, total - 2, total - 1, total];
                } else {
                    return [1, 2, 3, 4, 5, total];
                }
            }
        },
        [filterPages]
    );

    /**
     * handle page change
     * @param page - current page
     * @returns
     */
    const changePage = (page) => {
        const activePage = pageIndex;

        if (page === activePage) {
            return;
        }

        const visiblePages = getVisiblePages(page, pageCount);
        setVisiblePages(filterPages(visiblePages, pageCount));
        handleChangePage(page);
        // tableProps.gotoPage(page - 1);
    };

    useEffect(() => {
        const visiblePages = getVisiblePages(null, pageCount);
        setVisiblePages(visiblePages);
    }, [pageCount, getVisiblePages]);

    const [visiblePages, setVisiblePages] = useState(getVisiblePages(null, pageCount));
    const activePage = pageIndex;

    return (
        <>
            <div className="d-lg-flex align-items-center text-center pb-1 mt-3 gap-2">
                <span className="">
                    Page{' '}
                    <strong>
                        {pageIndex} of {tableProps.pageCount}
                    </strong>{' '}
                </span>
                <span className="me-3">
                    - Total {' '}
                    <strong>
                        {tableProps.state.totalResults}
                    </strong>{' '}
                </span>

                {/* <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
                    <label>Go to page : </label>
                    <input
                        type="number"
                        value={pageIndex}
                        min="1"
                        onChange={(e: any) => {
                            const page = e.target.value ? Number(e.target.value):0;
                            changePage(page);
                        }}
                        className="form-control w-25 ms-1 d-inline-block"
                    />
                </span> */}

                <ul className="pagination pagination-rounded d-inline-flex ms-auto">
                    <li
                        key="prevpage"
                        className={classNames('page-item', 'paginate_button', 'previous', {
                            disabled: activePage === 1,
                        })}
                        onClick={() => {
                            if (activePage === 1) return;
                            changePage(activePage - 1);
                        }}>
                        <Link to="#" className="page-link">
                            <i className="theme-icon icon-back1"></i>
                        </Link>
                    </li>
                    {(visiblePages || []).map((page, index, array) => {
                        return array[index - 1] + 1 < page ? (
                            <React.Fragment key={page}>
                                <li className="page-item disabled d-none d-xl-inline-block">
                                    <Link to="#" className="page-link">
                                        ...
                                    </Link>
                                </li>
                                <li
                                    className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                        active: activePage === page,
                                    })}
                                    onClick={(e) => changePage(page)}>
                                    <Link to="#" className="page-link">
                                        {page}
                                    </Link>
                                </li>
                            </React.Fragment>
                        ) : (
                            <li
                                key={page}
                                className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                    active: activePage === page,
                                })}
                                onClick={(e) => changePage(page)}>
                                <Link to="#" className="page-link">
                                    {page}
                                </Link>
                            </li>
                        );
                    })}
                    <li
                        key="nextpage"
                        className={classNames('page-item', 'paginate_button', 'next', {
                            disabled: activePage === tableProps.pageCount,
                        })}
                        onClick={() => {
                            if (activePage === tableProps.pageCount) return;
                            changePage(activePage + 1);
                        }}>
                        <Link to="#" className="page-link">
                            <i className="theme-icon icon-next_arrow"></i>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default CustomPagination;
