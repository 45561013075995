import { useContext, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "../../context/themeContext";
import Images from "../../helpers/images";
import { isDesktop, isMobile } from "react-device-detect";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
const SideBar = ({
    show,
    handleClose
}) => {
    const { toggle } = useContext(ThemeContext);

    const location = useLocation();
    const currentPath = location?.pathname;

    const [activeLinkName, setActiveLinkName] = useState('#home');

    useEffect(() => {
        if (currentPath === '/') {
            setActiveLinkName(location?.hash);
        } else {
            setActiveLinkName(currentPath);
        }
    }, [location])

        // for lang change
        const { t, i18n } = useTranslation();

        const changeLanguage = (event) => {
          const selectedLanguage = event.target.value;
          i18n.changeLanguage(selectedLanguage);
        };
    
    return (
        <>
            <Offcanvas className="offcanvas-wrapper" placement={isMobile ? 'start' : 'end'} show={show} onHide={handleClose} >
                <>
                    {isMobile ? (
                        <div className="side-bar-logo">
                            {toggle === "light" ? (
                                <Link to="/"> <img loading="lazy" src={Images.Logo} className="img-fluid" alt="" /> </Link>
                            ) : (
                                <Link to="/"> <img loading="lazy" src={Images.logoWhite} className="img-fluid" alt="" /></Link>
                            )}
                            <Offcanvas.Header >
                                {toggle === 'light' ? (
                                    <div className="close-offcanvas"> <img loading="lazy" onClick={(e) => { handleClose() }} src={Images.closeBlack} className='img-fluid' alt='' /> </div>
                                ) : (
                                    <div className="close-offcanvas"> <img loading="lazy" onClick={(e) => { handleClose() }} src={Images.closeWhite} className='img-fluid' alt='' /> </div>
                                )}
                            </Offcanvas.Header>
                        </div>
                    ) : (
                        <Offcanvas.Header >
                            {toggle === 'light' ? (
                                <div className="close-offcanvas"> <img loading="lazy" onClick={(e) => { handleClose() }} src={Images.closeBlack} className='img-fluid' alt='' /> </div>
                            ) : (
                                <div className="close-offcanvas"> <img loading="lazy" onClick={(e) => { handleClose() }} src={Images.closeWhite} className='img-fluid' alt='' /> </div>
                            )}
                        </Offcanvas.Header>
                    )
                    }
                </>
                <Offcanvas.Body>
                    <div className="menu-link">
                        <ul>
                            <li onClick={() => { handleClose() }}>{currentPath !== "/" ? (<HashLink smooth to="/#home"> {t("Home")}</HashLink>) : (<a smooth href="#home"> {t("Home")}</a>)}</li>
                            <li onClick={() => { handleClose() }}>{currentPath !== "/" ? (<HashLink smooth to="/#AboutUs"> {t("AboutUs")} </HashLink>) : (<a smooth href="#AboutUs">{t("AboutUs")} </a>)}</li>
                            <li onClick={() => { handleClose() }}>{currentPath !== "/" ? (<HashLink smooth to="/#Products">{t("Products")} </HashLink>) : (<a smooth href="#Products">{t("Products")} </a>)}</li>
                            <li onClick={() => { handleClose() }}>{currentPath !== "/" ? (<HashLink smooth to="/#Services">{t("Services")} </HashLink>) : (<a smooth href="#Services">{t("Services")} </a>)}</li>
                            <li onClick={() => { handleClose() }}>{currentPath !== "/" ? (<HashLink smooth to="/#Sustainability">{t("Sustainability")} </HashLink>) : (<a smooth href="#Sustainability">{t("Sustainability")} </a>)}</li>
                            {/* <li onClick={() => { handleClose() }}><Link to='/sustainability'>Sustainability </Link></li> */}
                            <li onClick={() => { handleClose() }}><Link to='/investor-relations'> {t("Investor Relations")} </Link></li>
                            <li onClick={() => { handleClose() }}><Link to='/career'> {t("Career")} </Link></li>
                            <li onClick={() => { handleClose() }}><Link to='/news-events-and-awards'> {t("News, Events & Awards")}</Link></li>
                            <li onClick={() => { handleClose() }}><Link to='/contact-us'> {t("Contact Us")}</Link></li>
                            {/* <li onClick={() => { handleClose() }}><Link to='/'>Customer Login </Link></li> */}
                        </ul>
                        {/* <div className="btn-section">
                            <img loading="lazy" src={Images.Arrow} className='img-fluid' alt='' /> NSE 279.10 <span>(+3.77%)</span>
                        </div> */}
                    </div>
                    <>
                        {isMobile ? (
                            <div className="side-bar-social-icon">
                                <ul>
                                    <li><a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/poddar-pigments-limited/mycompany/?viewAsMember=true'><img loading="lazy" src={Images.linkedIn} className='img-fluid' alt='' /></a></li>
                                    <li><a target="_blank" rel="noreferrer" href='https://www.facebook.com/profile.php?id=100076247146704'><img loading="lazy" src={Images.fb} className='img-fluid' alt='' /></a></li>
                                    <li><a target="_blank" rel="noreferrer" href='https://www.instagram.com/poddarpigmentsltd/'><img loading="lazy" src={Images.insta} className='img-fluid' alt='' /></a></li>
                                    <li><a target="_blank" rel="noreferrer" href='https://www.youtube.com/@PoddarPigmentsLimited'><img loading="lazy" src={Images.youTube} className='img-fluid' alt='' /></a></li>
                                </ul>
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default SideBar
