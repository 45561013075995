import React, { useEffect } from "react";
import Images from "../../../helpers/images";
import { Image } from "react-bootstrap";

const PrivateBook = ({ selectedDigitalShade, images, handleClose }) => {
  useEffect(() => {
    const pages = document.getElementsByClassName("page");

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      if (i % 2 === 0) {
        page.style.zIndex = pages.length - i;
      }
    }

    for (let i = 0; i < pages.length; i++) {
      pages[i].pageNum = i + 1;
      pages[i].addEventListener("click", function () {
        if (this.pageNum % 2 === 0) {
          this.classList.remove("flipped");
          const prevSibling = this.previousElementSibling;
          if (prevSibling) {
            prevSibling.classList.remove("flipped");
          }
        } else {
          this.classList.add("flipped");
          const nextSibling = this.nextElementSibling;
          if (nextSibling) {
            nextSibling.classList.add("flipped");
          }
        }
      });
    }
  }, []); // Empty dependency array to ensure it runs once after mounting

  return (
    <div className="book">
      <div id="pages" className={`pages ${selectedDigitalShade?.bookSize}`} >
        {images?.map((item, i) => (
          <div className="page" key={`pagesss${i}`} number={i}>
              <img src={item} className='img-fluid img1' alt='Your Image' />
          </div>
        ))}
        <button onClick={handleClose}>
          <Image src={Images.Close} />
        </button>
      </div>
    </div>
  );
};

export default PrivateBook;
