import { Route, Routes } from "react-router-dom"
import { BoardMembers, CSRInitiatives, DigitalShadeCard, ExperienceCenter, FunctionalAndAdditiveMasterbatches, Home, InvestorRelations, NewsAndEvent, Locations, ManMadeFibre, MarketingSupport, MasterbatchesForPlasticApplications, RdSupport, Services,  TechnicalServicesSupport, TheCompany, } from "./components"
import InvestorRelationsDetails from "./components/pages/investorRelations/details"
import Career from "./components/pages/career"
import NewsAndEventDetails from "./components/pages/newsAndEvents/details"
import CreateYourOwn from "./components/pages/services/createYourOwn"
import ContactUs from "./components/pages/contactUs"
import Sustainability1 from "./components/pages/sustainability"
import PrivateDigitalShadeCard from "./components/pages/services/privateDigitalShadeCard"
import People from "./components/pages/sustainability/people"
import Product from "./components/pages/sustainability/product"
import Process from "./components/pages/sustainability/Process"
import Privacy from "./components/pages/privacyAndTerms/privacy"
import Terms from "./components/pages/privacyAndTerms/terms"
import AboutUsPage from "./components/pages/aboutUs"
import ServicePage from "./components/pages/services"
import PageNotFound from "./components/pages/pageNotFound"
import { useContext } from "react"
import { LanguageContext } from "./context/LanguageContext"

const AppRoutes = () => {
  const { language } = useContext(LanguageContext);
  console.log(language);

  return (
    <div className={`wrapper-${language}`}>
      <Routes >
        <Route exact path="/" element={<Home />} />
        <Route exact path="/the-company" element={<TheCompany />} />
        <Route exact path="/board-members" element={<BoardMembers />} />
        <Route exact path="/csr-initiatives" element={<CSRInitiatives />} />
        {/* <Route exact path="/sustainability1" element={<Sustainability1 />} /> */}
        <Route exact path="/sustainability/process" element={<Process />} />
        <Route exact path="/sustainability/product" element={<Product />} />
        <Route exact path="/sustainability/people" element={<People />} />
        <Route exact path="/investor-relations" element={<InvestorRelations />} />
        <Route exact path="/investor-relations/details/:type/:report" element={<InvestorRelationsDetails />} />
        {/* <Route exact path="/investor-relations/details" element={<InvestorRelationsDetails />} /> */}
        <Route exact path="/locations" element={<Locations />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/r-and-d-Support" element={<RdSupport />} />
        <Route exact path="/technical-services-support" element={<TechnicalServicesSupport />} />
        <Route exact path="/marketing-support" element={<MarketingSupport />} />
        <Route exact path="/experience-center" element={<ExperienceCenter />} />
        <Route exact path="/digital-shade-card" element={<DigitalShadeCard />} />
        <Route exact path="/digital-shade-card/share/:id" element={<PrivateDigitalShadeCard />} />
        <Route exact path="/masterbatch-for-man-made-fiber" element={<ManMadeFibre />} />
        <Route exact path="/masterbatches-for-plastic-applications" element={<MasterbatchesForPlasticApplications />} />
        <Route exact path="/functional-and-additive-masterbatches" element={<FunctionalAndAdditiveMasterbatches />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/news-events-and-awards" element={<NewsAndEvent />} />
        <Route exact path="/news-events-and-awards/details/:title" element={<NewsAndEventDetails />} />
        <Route exact path="/create-your-own" element={<CreateYourOwn />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/terms-of-use" element={<Terms />} />
        <Route exact path="/about-us" element={<AboutUsPage />} />
        <Route exact path="/services" element={<ServicePage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

    </div>
  )
}

export default AppRoutes