import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import Images from "../../../helpers/images";
import { Link } from "react-router-dom";
import CommonMeta from "../../comman/CommonMeta";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const RdSupport = () => {
  const { width } = useWindowDimensions();

  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <CommonMeta
        title="Poddar Pigments Ltd: Leading R&D for Antimicrobial Masterbatches."
        description="Explore PPL's cutting-edge R&D support, equipped with state-of-the-art pilot machines for multifilament yarns, blown film, and injection moulding. Partner with us for innovative solutions."
        keywords="Antimicrobial Masterbatches, masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="R & D Support"
        type="article"
      />
      <Header />
      <div className="top-banner-image r-d-services-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1>{t("R & D Support")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/technical-services-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>  {t("Technical Support")}</h4>
                      ) : (
                        <>
                          <h4>  {t("Technical Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/marketing-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Marketing Support")}</h4>
                      ) : (
                        <>
                          <h4>  {t("Marketing Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/experience-center">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>  {t("Experience Center")}</h4>
                      ) : (
                        <>
                          <h4>  {t("Experience Center")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/digital-shade-card">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Digital Shade Card")}</h4>
                      ) : (
                        <>
                          <h4> {t("Digital Shade Card")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("R & D Support")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-desc-section">
        <Container>
          <Row>
            <Col lg={6}>
              <p>
                {t("At Poddar Pigments Limited (PPL), we take immense pride in our Research and Development (R&D) capabilities, which are geared to continuously innovate and develop value-added products. We understand that the needs of our clients and their customers are perpetually changing, and we strive to stay ahead of the curve by offering cutting-edge solutions.")}</p>
              <h5>{t("State-of-the-Art Pilot Machines")}</h5>
              <ul>
                <li> <strong> {t("Multifilament Yarns")}</strong></li>
                <li><strong>{t("Blown Film")} </strong></li>
                <li><strong>{t("Injection Moulding")} </strong></li>
              </ul>
            </Col>
            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.rd1}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.rd2}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="">

                <h5>{t("Simulating Real-World Conditions")} </h5>
                <ul>
                  <li>
                    <strong>{t("Precision Testing :")}  </strong>{t("Our pilot plants replicate real-world manufacturing conditions.")}
                  </li>
                  <li>
                    <strong> {t("Insightful Development :")} </strong> {t("We contribute valuable insights based on our industry expertise during product development.")}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="technical-banner">
                <img
                  loading="lazy"
                  src={Images.rdBanner}
                  className="img-fluid img1"
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <div>

                <h5> {t("Leveraging Our Extensive Database")} </h5>
                <ul>
                  <li>
                    <strong>{t("Speedy Solutions :")} </strong> {t("We leverage our extensive database for rapid development and colour matching.")} 
                  </li>
                  <li>
                    <strong> {t("Custom Requirements :")}  </strong>
                    {t("We provide tailor-made masterbatch solutions to meet your specific needs promptly.")}
                  </li>
                  <li>{t("Extensive Color Library (60,000+ Colors)")} </li>
                </ul>
              </div>
            </Col>
            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.rd3}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.rd4}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default RdSupport;
