import React from "react";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import CommonMeta from "../../comman/CommonMeta";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
    // for lang change
    const { t, i18n } = useTranslation();

    const changeLanguage = (event) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    };
  return (
    <>
      <CommonMeta
        title="Masterbatches Manufacturer in India - Poddar Pigments Limited"
        description="Poddar Pigments Limited, an ISO 9001:2015 QMS-certified company, specializes in manufacturing Color and Additive Masterbatches. We are the pioneers in manufacturing Masterbatches for dope-dyeing Polypropylene, Nylon and polyester Multifilament Yarn/Fibers in India."
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch,leading masterbatch manufacturing company in India"
        name="about-us"
        type="article"
      />
      <Header />
      <div className="top-banner-image about-tabs about-us-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1>{t("AboutUsPage.main-head")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/the-company">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("The Company")}</h4>
                      ) : (
                        <>
                          <h4>{t("The Company")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/board-members">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Board Members")}</h4>
                      ) : (
                        <>
                          <h4>{t("Board Members")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/csr-initiatives">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>  {t("CSR Initiatives")}</h4>
                      ) : (
                        <>
                          <h4>  {t("CSR Initiatives")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/locations">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Locations")} </h4>
                      ) : (
                        <>
                          <h4> {t("Locations")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("About-Us")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="about-us-page-wrapper">
              <h2>{t("AboutUsPage.title")}</h2>
              <p>
              {t("AboutUsPage.desc")}
              </p>
              {/* <p>
                Poddar Pigments Limited is a name synonymous with trusted
                masterbatch suppliers globally.{" "}
              </p> */}
              <div className="about-box-wrapper">
                <div className="box">
                  {/* <div className="numbring">
                    <p>1</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5> {t("AboutUsPage.box1.title")}</h5>
                      <li>
                      {t("AboutUsPage.box1.bullet1")}
                      </li>
                      <li>
                      {t("AboutUsPage.box1.bullet2")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>2</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5>{t("AboutUsPage.box2.title")}</h5>
                      <li>
                      {t("AboutUsPage.box2.bullet1")}
                      </li>
                      <li>
                      {t("AboutUsPage.box2.bullet2")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>3</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5>{t("AboutUsPage.box3.title")}</h5>
                      <li>
                      {t("AboutUsPage.box3.bullet1")}
                      </li>
                      <li>
                      {t("AboutUsPage.box3.bullet2")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>4</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5>{t("AboutUsPage.box4.title")}</h5>
                      <li>
                      {t("AboutUsPage.box4.bullet1")}
                      </li>
                      <li>
                      {t("AboutUsPage.box4.bullet2")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>5</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5>{t("AboutUsPage.box5.title")}</h5>
                      <li>
                      {t("AboutUsPage.box5.bullet1")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default AboutUsPage;
