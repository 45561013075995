import { Col, Container, Row } from "react-bootstrap";
import Footer from "../comman/footer";
import Header from "../comman/header";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className="pagenot-found-wrapper" lg={12}>
            <div className="pagenot-found-box">
              <h1>404</h1>
              <h2>Page Not Found !</h2>
              <p>
                The link you followed is outdated or incorrect. Check the URL for typing mistake and try again.
              </p>
              <p>We apologize for any inconvenience.</p>
              <div className="digital-card-btn-wrapper digital-card-btn">
              <Link className="digital-btn animated-button" to="/">Home</Link></div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default PageNotFound;
