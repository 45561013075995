import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import Images from "../../../helpers/images";
import { Link } from "react-router-dom";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const Process = () => {

  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <CommonMeta
        title="Sustainability Practices: Efficiency, Environment, and Ethics"
        description="Poddar Pigments Ltd: Sustainable Practices for Efficiency, Environment, and Ethics. Harnessing Solar Energy and Closed-Loop Systems"
        keywords="Sustainability Practices, masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Process"
        type="article"
      />
      <Header />
      <div className="top-banner-image sustainability-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1> {t("Sustainability")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper sustainbility-tab">
              <div className="card-box-wrapper">
                <Link to="/sustainability/product">
                  <div className="card-box">
                    <div className="name">
                      <h4> {t("Product")}</h4>
                      <div className="icon">
                        <i className="icon-next_arrow"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/sustainability/people">
                  <div className="card-box">
                    <div className="name">
                      <h4>{t("People")}</h4>
                      <div className="icon">
                        <i className="icon-next_arrow"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Process")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sustainable-wrap">
        <Container>
          <Row className="align-items-center heading-text-wrapp">
            <Col lg={12}>
              <div className="heading-text">
                <p> {t("Sustainability is no longer just a buzzword. It’s a crucial aspect of businesses today. Over the last decade, social consciousness has been on the rise among consumers. In turn, the business world has also had to place environmental and social issues higher on their corporate agendas. At Poddar Pigments Limited, we have taken this on top priority and have worked and are working on 3 critical aspects of the business, namely:")}
                </p>
                <div className="sustain-top-list">
                  <ul>
                    <li> <strong>{t("Process")}</strong></li>
                    <li><strong>{t("Product")}</strong></li>
                    <li><strong>{t("People")}</strong> </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="heading-text">
            <h2> {t("Process Sustainability")}</h2>
            <p> {t("Process Sustainability focuses on enduring efficiency, environmental responsibility, and ethical considerations within various operations and systems. It entails the ongoing evaluation and improvement of the processes to reduce waste, conserve resources, and minimize ecological footprints. By integrating sustainable principles into processes, many organizations and industries aim to foster long-term viability while minimizing negative impacts on the planet.")}
            </p>
          </Row>
          <Row>
            <div className="process-list">
              <h5> {t("Key Features")}</h5>
              <ul>
                <li>
                  <strong> {t("Harnessing Solar Energy :")} </strong> {t("At our Chaksu plant, we harness solar power for a greener future.")}
                </li>
                <li>

                  <strong> {t("Closed-Loop Water System :")} </strong> {t("Our production plants use a closed-loop water system, conserving resources.")}
                </li>
                <li>

                  <strong>  {t("Responsible Waste Management :")} </strong>{t("We follow standardized waste management practices and partially recycle waste.")}
                </li>
                <li>

                  <strong>  {t("Superior Spinning Performance :")} </strong> {t("Our masterbatches transform PET bottles into ecologically sustainable yarns and fibers.")}
                </li>
              </ul>
            </div>
          </Row>

          <Row className="align-items-center heading-text-wrapp mobile-view">
            <Col lg={7}>
              <div className="heading-text">
                <h2> {t("Powering Sustainability with Solar Energy")}</h2>
                <p>{t("In our pursuit of reducing our ecological footprint, we have equipped our plant in Chaksu with solar panels. By harnessing solar power, we are paving a greener path towards a more sustainable future.")}
                </p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="sustainable-living-wrapper">
                <div className="image-section-wrapper">
                  <div className="image-box box1">
                    <img
                      loading="lazy"
                      src={Images.SolarPowerEnvironment1}
                      className="img-fluid img-1"
                      alt=""
                    />
                    <img
                      loading="lazy"
                      src={Images.SolarPowerEnvironment3}
                      className="img-fluid img-2"
                      alt=""
                    />
                  </div>
                  <div className="image-box">
                    <img
                      loading="lazy"
                      src={Images.SolarPowerEnvironment2}
                      className="img-fluid img-3"
                      alt=""
                    />
                    <img
                      loading="lazy"
                      src={Images.SolarPowerEnvironment4}
                      className="img-fluid img-4"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center heading-text-wrapp mobile-view">
            <Col lg={5}>
              <div className="image-box1">
                <img
                  loading="lazy"
                  src={Images.WaterManagementNew}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={7}>
              <div className="heading-text">
                <h2> {t("Closed-Loop Water System for Resource Conservation")} </h2>
                <p> {t("We have implemented sustainable systems in our production plants, including the use of a closed-loop water system. This not only conserves water but also aligns with our commitment to process sustainability.")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center heading-text-wrapp">
            <Col lg={7}>
              <div className="heading-text">
                <h2> {t("Responsible Waste Management and Recycling Initiatives")}</h2>
                <p> {t("Furthermore, we are committed to responsible waste management. We follow standardized systems for waste disposal and recycle a significant portion of our waste. Our masterbatches exhibit superior spinning performance and optimum fastness properties, transforming PET bottles into ecologically sustainable yarns and fibers.")}</p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="image-box1">
                <img
                  loading="lazy"
                  src={Images.sustainability3}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default Process;
