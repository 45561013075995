import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import manMadeFiber from '.././../../helpers/manMadeFibre.json';
import CommonMeta from "../../comman/CommonMeta";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import ProductsGalleryInner from "./productsGalleryInner";
import { Col, Container, Row } from 'react-bootstrap';

const ManMadeFibre = () => {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [galleryItems, setGalleryItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [subCategoryDetails, setSubCategoryDetails] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  const location = useLocation();
  const currentTitle = new URLSearchParams(location.search).get("title");
  const currentSubTitle = new URLSearchParams(location.search).get("sub_title");
  const [categoryShow, setCategoryShow] = useState(false);
  const [subCategoryShow, setSubCategoryShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const getDetails = async () => {
      if (currentTitle && currentSubTitle) {
        const mainCategory = manMadeFiber?.find(
          (item) => item.title === currentTitle
        );
        if (mainCategory) {
          const subcategory = mainCategory?.sub_category?.find(
            (item) => item.name === currentSubTitle
          );
          setSubCategoryDetails(subcategory?.deatils);
        }
      }
    };

    getDetails();
  }, [currentTitle, currentSubTitle]);

  useEffect(() => {
    setCategories(currentData);

    document.onclick = function (e) {
      const child = e.target;
      const parent = document.getElementById("sidebar");
      if (parent) {
        const check = parent.contains(child);
        if (!check) {
          setCategoryShow(false);
          setSubCategoryShow(false);
        }
      }
    };
  }, [currentData]);

  useEffect(() => {
    const currentLanguage = i18n.language;
    const translatedData = getSelectedLanguageTranslation(currentLanguage);
    setCurrentData(translatedData);

    const category = searchParams.get("c");
    const subcategory = searchParams.get("sc");
    const smartProduct = searchParams.get("smp");

    if (category) {
      const catItem = translatedData.find((item) => item.title === category);
      if (catItem !== undefined) {
        if (smartProduct === "1") {
          const smartCategoryOutput = catItem.sub_category.find(
            (item) => item.name === "Smart Products"
          );
          if (smartCategoryOutput !== undefined) {
            const finalItem = smartCategoryOutput.sub_category.find(
              (item) => item.name === subcategory
            );
            if (finalItem !== undefined) {
              handleItemClick(finalItem, catItem);
            }
          }
        } else {
          const subCategoryOutput = catItem.sub_category.find(
            (item) => item.name === subcategory
          );
          if (subCategoryOutput !== undefined) {
            handleItemClick(subCategoryOutput, catItem);
          }
        }
      }
    } else {
      handleItemClick(translatedData[0]);
    }
  }, [i18n.language, searchParams]);

  const getSelectedLanguageTranslation = (currentLanguage) => {
    switch (currentLanguage) {
      case 'en':
        return require('.././../../helpers/productLangData/manMadefibre/manMadeFibre_en.json');
      case 'es':
        return require('.././../../helpers/productLangData/manMadefibre/manMadeFibre_es.json');
      case 'de':
        return require('.././../../helpers/productLangData/manMadefibre/manMadeFibre_de.json');
      case 'fr':
        return require('.././../../helpers/productLangData/manMadefibre/manMadeFibre_fr.json');
      case 'pt':
        return require('.././../../helpers/productLangData/manMadefibre/manMadeFibre_pt.json');
      default:
        return require('.././../../helpers/productLangData/manMadefibre/manMadeFibre_en.json');
    }
  };

  const categoryToggle = (item) => {
    setSelectedCategory(item)
    setCategoryShow(false);
    setSubCategories(item.sub_category);
    setTimeout(() => {
      setCategoryShow(true);
    }, 400);
  };

  const subCategoryToggle = (item) => {
    if (item.sub_category && item.sub_category.length > 0) {
      setSubCategoryShow(true);
    } else {
      handleItemClick(item);
    }
  };

  const handleItemClick = (item, catItem = null) => {
    if (catItem) {
      setSelectedCategory(catItem);
      setGalleryItems(catItem);
    } else {
      if (!selectedCategory) {
        setSelectedCategory(item);
      }
      setGalleryItems(selectedCategory ? selectedCategory : item);
    }
    setSelectedItem(item);
    setCategoryShow(false);
    setSubCategoryShow(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }

  return (
    <>
      <CommonMeta
        title="Masterbatches Manufacturer in India - Poddar Pigments Limited"
        description="Explore Poddar Pigments Limited's top-quality masterbatches for polyester fibers. Our mono-concentrates are designed for efficient and cost-effective mass coloration of polyester fibers and yarn. The advantages of our masterbatches include heat stability, sublimation resistance, and superior color strength."
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="products"
        type="article"
      />
      <Header />
      <div className="product-wrapper-section">
      <Row className="g-0">
          <div className="product-category-wrapp-main">
            <div className="sidebar-sec-ineer">
              <div className="man-made-fibre-category-filter" id="sidebar">
                <div className="category-title">
                  <h4> {t("Masterbatch for Man-Made Fiber")}</h4>
                </div>
                <ul>
                  {currentData.map((item, i) => (
                    <div key={i}>
                      <li>
                        <div onClick={() => categoryToggle(item)}><i className="icon icon-polymide_default"></i> <div className="cat-item">{t(`${item.title}`)}</div></div>
                        <div className={`categoryShow ${categoryShow ? 'visible' : 'hidden'}`}>
                          {subCategories.length > 0 && (
                            <ul>
                              {subCategories.map((subCategory, j) => (
                                <div key={j}>
                                  <li>
                                    <span
                                      onClick={() =>
                                        subCategoryToggle(subCategory)
                                      }
                                    >
                                     {t(`${subCategory.name}`)}
                                    </span>
                                    {subCategory?.sub_category?.length > 0 &&
                                      subCategory.sub_category && (
                                        <div
                                          className={`categoryShow subcategoryShow ${subCategoryShow ? "visible" : "hidden"
                                            }`}
                                        >
                                          <ul>
                                            {subCategory.sub_category.map(
                                              (subSubCategory, k) => (
                                                <div key={k}>
                                                  <li
                                                    onClick={() =>
                                                      handleItemClick(subSubCategory)
                                                    }
                                                  >
                                                    <span>
                                                       {t(`${subSubCategory.name}`)}
                                                    </span>
                                                  </li>
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                  </li>
                                </div>
                              ))}
                            </ul>
                          )}
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className="product-details m-3">
              <div className="product-head-button">
                <Link
                  to="/masterbatches-for-plastic-applications"
                  className="product-btn animated-button"
                >{t("Explore Masterbatches for Plastic Applications")}
                  <i className="icon-next"></i>
                </Link>
                <Link
                  to="/functional-and-additive-masterbatches"
                  className="product-btn animated-button"
                >
                  {t("Explore Functional and Additive Masterbatches")}
                  <i className="icon-next"></i>
                </Link>
              </div>
              <div className="product-details-images-section">
                <div className="product-image">

                  <img
                    width={"100%"}
                    loading="lazy"
                    src= {`${selectedItem?.image}`}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className={`${selectedItem?.black_heading ? 'black' : ''} product-title-name`}>{t(`${selectedItem?.name}`)}</h3>
              </div>
              <ProductsGalleryInner items={galleryItems} selectedItem={selectedItem} handleItemClick={(item) => handleItemClick(item)} />
              <div className="breadcrumb-wrapper product-breadcrumb">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/"> {t("Home")}</Link></li>
                          <li className="breadcrumb-item  active"> {t("Masterbatch for Man-Made Fiber")}</li>
                        </ol>
                      </nav>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="mt-4" dangerouslySetInnerHTML={{ __html: selectedItem?.desc }}></div>
            </div>
          </div>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default ManMadeFibre;
