import { Col, Container, Row } from "react-bootstrap";
import Header from "../../comman/header";
import Footer from "../../comman/footer";
import IndiaMap from "./indiaMap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const Locations = () => {
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <CommonMeta
        title="Poddar Pigments Ltd: Leading Masterbatch Manufacturer in India."
        description="Poddar Pigments Ltd: Leading Masterbatch Manufacturer in India with State-of-the-Art Facilities for Polypropylene, Nylon, and Polyester Yarn."
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Locations"
        type="article"
      />
      <Header />
      <div className="top-banner-image about-tabs location-bg">
        <Container>
          <Row>
            <div className="top-heading-text">{/* <h1> Locations</h1> */}</div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/about-us">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("About-Us")}</h4>
                      ) : (
                        <>
                          <h4>{t("About-Us")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/the-company">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("The Company")}</h4>
                      ) : (
                        <>
                          <h4>{t("The Company")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/board-members">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Board Members")}</h4>
                      ) : (
                        <>
                          <h4> {t("Board Members")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/csr-initiatives">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("CSR Initiatives")}</h4>
                      ) : (
                        <>
                          <h4>{t("CSR Initiatives")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("Locations")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="our-location-india">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="map-section">
                <IndiaMap />
              </div>
            </Col>
            <Col lg={5}>
              <div className="heading-text">
                <h2>{t("Corporate Office")} </h2>
              </div>
              <div className="our-location">
                <h3> CHENNAI</h3>
                <p>
                  Rosy Tower, 3rd Floor 8, Mahatma Gandhi Road, Nungambakkam,
                  Chennai - 600034
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:(91) 44 2826 0929">(+91) 44 2826 0929,</a>
                  {/* <a href="tel:(91) 44 4500 8003">4500 8003</a> */}
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:headoffice@poddarpigmentsltd.com">
                    headoffice@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
              <div className="heading-text">
                <h2> {t("Registered Office and Works")}</h2>
              </div>
              <div className="our-location">
                <h3>JAIPUR WORKS</h3>
                <p>
                  E-10, 11, F-14 to 16, RIICO Industrial Area, Sitapura, Jaipur
                  - 302022 (Rajasthan)
                </p>
                <p>CIN: L24117RJ1991PLC006307</p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:+91 141 2770 202 - 203">
                    (+91) 141 2770 202 - 203
                  </a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:jaipur@poddarpigmentsltd.com">
                    jaipur@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
              <div className="our-location">
                <h3>CHAKSU WORKS </h3>
                <p>
                  Greater Sitapura Industrial Park, National Highway 12
                  (Jaipur-Tonk Road), Village - Brijpura (Chaksu), Jaipur -
                  303901
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <div className="heading-text my-4">
                <h2> {t("Regional Offices")}</h2>
              </div>
            </Col>
            <Col lg={4}>
              <div className="our-location">
                <h3>CHENNAI</h3>
                <p>
                  Rosy Tower, 3rd Floor, #8, Mahatma Gandhi Road, Nungambakkam,
                  Chennai - 600034
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel: (91) 44 2826 0929"> (+91) 44 2826 0929</a>
                  {/* <a href="tel: (91) 4500 8003"> 4500 8003</a> */}
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:southmktg@poddarpigmentsltd.com">
                    southmktg@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
            </Col>

            <Col lg={4}>
              <div className="our-location">
                <h3>NEW DELHI</h3>
                <p>
                  A-283, Ground Floor, Okhla Phase I, Okhla Industrial Area,
                  Delhi - 110020
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:(91) 11 4100 5204">(+91) 11 4100 5204</a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:delhi@poddarpigmentsltd.com">
                    delhi@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="our-location">
                <h3>KOLKATA</h3>
                <p>
                  Mangalam-B, 2nd Floor, 26, Hemanta Basu Sarani,Kolkata -
                  700001
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:(91) 33 2231 8147">(+91) 33 2231 8147</a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:kolkata@poddarpigmentsltd.com">
                    kolkata@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="our-location">
                <h3>MUMBAI</h3>
                <p>
                  #302, Nanak Chambers, 3rd floor, (Opp. Fun Republic) New Link
                  Road, Andheri (West), Mumbai - 400053
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:(91) 22 4002 0304">(+91) 22 4002 0304</a> ,
                  <a href="tel:(91) 022 4014 2216"> 022 4014 2216</a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:mumbai@poddarpigmentsltd.com">
                    mumbai@poddarpigmentsltd.com
                  </a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:wzmktg@poddarpigmentsltd.com">
                    wzmktg@poddarpigmentsltd.com
                  </a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:export@poddarpigmentsltd.com">
                    export@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="our-location">
                <h3>HYDERABAD</h3>
                <p>
                  Plot # 10, House Number: 2-4-937/1, Samathapuri Colony,Near
                  New Nagole Bus Stop,Saroornagar (Post), Hyderabad,Telangana,
                  India. PIN Code 500035
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:9391136290">(+91) 9391136290</a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:hyderabad@poddarpigmentsltd.com">
                    hyderabad@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default Locations;
