import { Col, Container, Row } from "react-bootstrap";
import Header from "../../comman/header";
import Footer from "../../comman/footer";
import Images from "../../../helpers/images";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/themeContext";
import CommonMeta from "../../comman/CommonMeta";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
const TheCompany1 = () => {
  const { toggle, toggleFunction } = useContext(ThemeContext);
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <CommonMeta
        title="Masterbatches Manufacturer in India - Poddar Pigments Limited"
        description="Poddar Pigments Limited, an ISO 9001:2015 QMS-certified company, is the leading masterbatches manufacturer in India with a global presence in over 30 countries. Explore our commitment to quality, innovation, and eco-friendly practices."
        keywords="Dyeing & Masterbatch Manufacturer"
        name="The Company"
        type="article"
      />
      <Header />
      <div className="top-banner-image about-tabs company-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1>{t("The Company")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/about-us">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("About-Us")}</h4>
                      ) : (
                        <>
                          <h4>{t("About-Us")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/board-members">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Board Members")}</h4>
                      ) : (
                        <>
                          <h4>{t("Board Members")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/csr-initiatives">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("CSR Initiatives")}</h4>
                      ) : (
                        <>
                          <h4> {t("CSR Initiatives")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/locations">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Locations")}</h4>
                      ) : (
                        <>
                          <h4>{t("Locations")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("The Company")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="founder-message">
        <Container>
          <Row className="">
            <Col lg={4}>
              <div className="founder-image">
                <img
                  loading="lazy"
                  src={Images.ShriSSPoddar}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="heading-text-leadership mt-2">
                <h3 className="text-center">
                  <strong> Shri S. S. Poddar </strong>
                </h3>
                <h4 className="text-center">
                  <strong> {t("(Our founder)")}</strong>
                </h4>
              </div>
            </Col>
            <Col lg={8}>
              <div className="founder-text">
                <div>
                  <h5>{t("The Company")}</h5>
                  <p>
                    {t("TheCompanyDesc")}
                  </p>
                </div>
                <div>
                  <h5> {t("Global Reach")}</h5>
                  <p>
                    {t("GlobalReachDesc")}
                  </p>
                </div>
                <div>
                  <h5>{t("Innovative Sustainability")} </h5>
                  <p>
                    {t("InnovativeSustainabilityDesc")}
                  </p>
                </div>
                <div>
                  <h5> {t("Commitment to Excellence")}</h5>
                  <p>
                    {t("CommitmentToExcellenceDesc")}
                  </p>
                </div>
                <div>
                  <h5> {t("The Poddar Advantage®")}</h5>
                  <p>
                    {t("ThePoddarAdvantageDesc")}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="ourJourney1">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-section">
                <h1>{t("Our Journey And Vision")}</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <img
                loading="lazy"
                src={
                  toggle === "light" ? Images.ourJourney : Images.ourJourneyDark
                }
                className="img-fluid"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="about-our-creation-company">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-section">
                <h1> {t("Gearing for The Next Big Leap")}</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <div className="card-box mb-3">
                <div className="overlay"></div>
                <div className="card-body">
                  <h3> {t("Infrastructure")}</h3>
                  <p>
                    {t("Chaksu plant launched with investment of ₹85 crores Increases total installed capacity by 25%")}

                  </p>
                  <p> {t("Fully automated lines")} </p>
                  <p>{t("Further upgradations in Sitapura plant")} </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="card-box mb-3">
                <div className="overlay"></div>
                <div className="card-body">
                  <h3>{t("IT and Process Digitization")}</h3>
                  {/* <p>
                    Sitapura & Chaksu Plants launched for ISO 14000
                    certification in 2022
                  </p> */}
                  <p>
                    {t("Operations fully digitized through Oracle based ERP system")}

                  </p>
                  <p>
                    {t("SOP development for fully digitised operations")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="card-box mb-3">
                <div className="overlay"></div>
                <div className="card-body">
                  <h3>{t("People Development")} </h3>
                  <p> {t("Leadership coaching program for CXO team members")}</p>
                  <p>{t("Creation of CFTs")} </p>
                  <p>
                    {t("Progressing from manufacturing led to a sales and marketing driven organisation")}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className="card-box mb-3">
                <div className="overlay"></div>
                <div className="card-body">
                  <h3>{t("Innovation")} </h3>
                  <p>
                  {t("Deeper focus in recycled space for Sustainable Prosperity")}
                  </p>
                  <p>{t("World class R&D and Labs")}</p>
                  <p>{t("Promote processes to reduce CO2 emissions")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default TheCompany1;
