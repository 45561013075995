import { Col, Container, Row } from "react-bootstrap";
import Images from "../../helpers/images";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import NonStickyHeaderActions from "./nonStickyHeaderActions";
import StickyHeaderActions from "./stickyHeaderActions";
import { ThemeContext } from "../../context/themeContext";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
const Header = () => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);

  const stickyHeader = useRef();
  useLayoutEffect(() => {
    const mainHeader = document.getElementById("mainHeader");
    let fixedTop = stickyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        mainHeader.classList.add("fixedTop");
        // if (document.body) document.body.classList.add('headerFixed')
        setShowStickyHeader(true);
      } else {
        mainHeader.classList.remove("fixedTop");
        // if (document.body) document.body.classList.remove('headerFixed')
        setShowStickyHeader(false);
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  const [sidebarToggle, setSidebarToggle] = useState(false);

  const { toggle } = useContext(ThemeContext);

  return (
    <div
    className="header-wrapper mainHeader"
    id="mainHeader"
    ref={stickyHeader}
    >
      <Container>
        <Row className="align-items-center mobile-menu">
          <Col className="mobile-logo-wrapper" lg={3}>
            {toggle === "light" ? (
              <div className="logo">
                <Link to="/">
                  <img
                    loading="lazy"
                    src={Images.Logo}
                    className="img-fluid"
                    alt=""
                  />{" "}
                </Link>
              </div>
            ) : (
              <div className="logo">
                <Link to="/">
                  <img
                    loading="lazy"
                    src={Images.logoWhite}
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            )}
          </Col>
          <Col className="mobile-menu-wrapper" lg={9}>
            {!showStickyHeader ? (
              ((
                <NonStickyHeaderActions
                  sidebarToggle={sidebarToggle}
                  setSidebarToggle={setSidebarToggle}
                />
              ),
              (
                <>
                  <NonStickyHeaderActions
                    sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}
                  />
                </>
              ))
            ) : (
              <>
                {isMobile ? (
                  <NonStickyHeaderActions
                    sidebarToggle={sidebarToggle}
                    setSidebarToggle={setSidebarToggle}
                  />
                ) : (
                  <StickyHeaderActions />
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Header;
