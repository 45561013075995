import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Footer from "../comman/footer";
import Header from "../comman/header";
import Images from "../../helpers/images";
import { Link, useLocation } from "react-router-dom";
import { showToast, hasError } from "../../helpers/utils";

import countryCode from "../../helpers/data/countryCode.json";
import {
  applyForJob as applyForJobApi,
  getJobTitles as getJobTitlesApi,
  uploadFile as uploadFileApi,
} from "../../helpers/api";
import CommonMeta from "../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const Career = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const title = queryParams.get("title");

  const [jobTitles, setJobTitles] = useState([]);
  const [formData, setFormData] = useState({});
  const [fileName, setFileName] = useState("");
  const countryCodes = countryCode;
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    getJobTitles();
  }, []);

  const handleOnChange = (name, value) => {
    let errors = validationErrors.filter((err) => err.key !== name);
    setValidationErrors(errors);
    setFormData((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const clearFile = () => {
    setFormData((current) => {
      const { resume, ...rest } = current;
      return rest;
    });

    setFileName("");
  };

  const getJobTitles = async () => {
    try {
      const { data } = await getJobTitlesApi(formData);
      setJobTitles(data.result);
      setLoading(false);
    } catch (error) {
      showToast("error", error);
    }
  };

  const handleUpload = async (input) => {
    const file = input.files[0];
    setFileName(file.name);
    const { data } = await uploadFileApi({ resume: file });
    if (data?.status) {
      const errors = validationErrors.filter((err) => err?.key !== "resume");
      setValidationErrors(errors);
      setFormData((prevState) => ({
        ...prevState,
        resume: data?.data.filename,
      }));
    }
  };

  const submit = async () => {
    const params = {
      ...formData,
      phoneCode: formData?.phoneCode?.value,
    };
    try {
      await applyForJobApi(params);
      showToast("success", "Your request has been submitted successfully.");
      setLoading(false);
      setFormData({});
      setValidationErrors([]);
    } catch (error) {
      setLoading(false);
      if (error.status === 422) {
        setValidationErrors(error?.error);
        showToast("error", "Please Check form values again.");
      } else {
        showToast("error", error);
      }
    }
  };
  // const nextCategory = () => {
  //   return <PageNotFound />;
  // };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Remove and upload New
    </Tooltip>
  );
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }
  return (
    <>
      <CommonMeta
        title="Poddar Pigments Ltd Careers: Join Our Team for Exciting Opportunities"
        description="Explore exciting career opportunities at Poddar Pigments Ltd and join our dynamic team. Discover your potential with us!"
        keywords="masterbatch India, masterbatches manufacturer in India, colour masterbatch, Career"
        name="Career"
        type="article"
      />
      <Header />
      <div className="top-banner-image career-bg"></div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("Career")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="news-events-wrapper">
        <Row>
          <Col lg={6}>
            <div className="career-left-box career-left-wrapp">
              <h5 className="mb-3">
                 {t("Join the Poddar Pigments Family : Exciting Career Opportunities")}
              </h5>
              <p> {t("Poddar Pigments invites you to embark on a remarkable journey that celebrates growth, innovation, and community. Our culture is a fertile ground for excellence, thriving in a dynamic environment where your ideas are not only welcomed but cherished, and collaboration is at the heart of our success. As leaders in masterbatch solutions and colour innovations, we embrace innovation as our way of life, consistently pushing boundaries to shape industry revolutions.")}
              </p>
              <h5> {t("Why Choose a Career at Poddar Pigments ?")}</h5>
              <ul>
                <li>
                  <strong> {t("Professional Growth :")} </strong> {t("Your professional journey is our priority. We are dedicated to your advancement through mentorship, specialized training, and diverse projects, ensuring that your career reaches new heights.")}
                </li>
                <li>
                  <strong>{t("Work-Life Balance :")}</strong> {t("We understand the importance of balance in life. Poddar Pigments provides an environment where you can excel in your career while also enjoying your personal passions.")}
                </li>
                <li>
                  <strong>{t("Diversity and Inclusion:")} </strong>  {t("Diversity is at the heart of our foundation. We nurture the uniqueness of every individual, creating a welcoming environment where everyone thrives.")}
                </li>
              </ul>
              <h5> {t("Make Your Mark with Poddar Pigments")}</h5>
              <p> {t("Join us in shaping industries, bringing products to life, and leaving an indelible mark on the world. Explore our current career opportunities and become a part of the Poddar Pigments family, where your aspirations find a home, and your career reaches new horizons. Your canvas of opportunities awaits!")}
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="career-right-box">
              <h5> {t("Submit your Details")}</h5>
              <p> {t("Please provide as much information as possible and we’ll contact you to advise you our next steps forward for the Recruitment.")}
              </p>
              <Form>
                <Row className="form-wrap">
                  <Col>
                    <div className="form-item">
                      <Form.Control
                        type="text"
                        name="firstName"
                        id="firstName"
                        required
                        value={formData?.firstName || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="firstName"> {t("First Name *")}</label>
                    </div>
                    {hasError(validationErrors, "firstName")}
                  </Col>
                  <Col>
                    <div className="form-item">
                      <Form.Control
                        type="text"
                        name="lastName"
                        id="lastName"
                        required
                        value={formData?.lastName || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="lastName"> {t("Last Name *")} </label>
                    </div>
                    {hasError(validationErrors, "lastName")}
                  </Col>
                </Row>
                <Row className="form-wrap">
                <Col>
                    <div className="form-item">
                      <Form.Control
                        type="email"
                        name="email"
                        id="email"
                        required
                        value={formData?.email || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="email"> {t("Email *")}  </label>
                    </div>
                    {hasError(validationErrors, "email")}
                    </Col>
                    <Col>
                    <div className="form-item">
                      <Form.Control
                        type="number"
                        name="phone"
                        id="phone"
                        required
                        value={formData?.phone || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="phone"> {t("Phone *")}</label>
                    </div>
                    
                    {hasError(validationErrors, "phone")}
                   
                    </Col>
                </Row>
                <Row className="form-wrap">
                  <Col>
                    <div className="form-item">
                      <Form.Control
                        type="number"
                        name="experience"
                        id="experience"
                        required
                        value={formData?.experience || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="experience"> {t("Experience *")}</label>
                    </div>
                    {hasError(validationErrors, "experience")}
                  </Col>
                  <Col>
                    <Form.Select
                      name="jobTitle"
                      value={formData?.jobTitle || ""}
                      onChange={(e) => {
                        handleOnChange(e.target.name, e.target.value);
                      }}
                    >
                      <option> {t("Applying for")}</option>
                      {jobTitles?.map((item, index) => (
                        <option key={`titles${index}`} value={item?.title}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>

                    {hasError(validationErrors, "jobTitle")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!fileName && (
                      <div className="upload-wrapper">
                        <input
                          className="upload-area"
                          type="file"
                          onChange={(e) => handleUpload(e.target)}
                          name="resume"
                        />
                        <i className="icon-cloud_upload"></i>
                        <strong> {t("Upload Resume")}</strong>
                        <span>
                          {t("doc, xls, pdf, txt, ppt and zip files only")}
                        </span>
                      </div>
                    )}
                    {fileName && (
                      <div className="file-name">
                        <small className="text-primary">{fileName}</small>
                        <span
                          className="close-image"
                          onClick={() => clearFile()}
                        >
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <img
                              loading="lazy"
                              src={Images.Close}
                              className="img-fluid icon-close"
                              alt=""
                            />
                          </OverlayTrigger>
                        </span>
                      </div>
                    )}
                    {hasError(validationErrors, "resume")}
                  </Col>
                </Row>
                <div className="digital-card-btn mt-3">
                  <a
                    className="digital-btn animated-button"
                    onClick={() => submit()}
                  >
                     {t("Submit")}
                  </a>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default Career;
