import { Col, Row } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductsGalleryInner = ({ items, selectedItem, handleItemClick }) => {
  var settings = {
    dots: false,
    arrow: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    autoplay: false,
    // autoplaySpeed: 2000,
    slidesToScroll: 4,
    centerMode: false,
    centerPadding: '50px',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScrol1: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }
  return (
    <div className="product-category-section product-category-inner-section">
      <Row className="g-0">
        <div className="product-category-section-wrapper">
          <div className="product-category-right product-category-width">
            <div className="product-category-tab">
              <Row className="g-0">

                <Col lg={12}>
                  {
                    items && items?.sub_category?.length > 0 &&
                    <Slider {...settings}>
                      {items.sub_category?.map((item, i) => (
                        item.name === "Smart Products"
                          ? (
                            item?.sub_category?.map((inner, j) => (
                              inner.name !== selectedItem.name &&
                              <div key={i} className="slider-box-wrapper" onClick={() => handleItemClick(inner)}>
                                <div className="effect-lily">
                                  <Link>
                                    <div className="image-overlay"></div>
                                    <div className="image-section">
                                      <div className="img-overlay"></div>
                                      <img loading="lazy" src={`${inner?.small_img}`} className='img-fluid' alt='' />
                                    </div>
                                    <div className="gallery-slider-text">
                                      <div className="gallery-slider-text-inner">
                                        <h5> {t(`${inner?.short_name}`)}</h5>
                                        <div className="read-more-link"><span> {t("more")} {'>'}</span></div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            )))
                          : (
                            item.name !== selectedItem.name &&
                            <div key={i} className="slider-box-wrapper" onClick={() => handleItemClick(item)}>
                              <div className="effect-lily">
                                <Link>
                                  <div className="image-overlay"></div>
                                  <div className="image-section">
                                    <div className="img-overlay"></div>
                                    <img loading="lazy" src={`${item?.small_img}`} className='img-fluid' alt='' />
                                  </div>
                                  <div className="gallery-slider-text">
                                    <div className="gallery-slider-text-inner">
                                      <h5> {t(`${item?.short_name}`)}</h5>
                                      <div className="read-more-link"><span>{t("more")} {'>'}</span></div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )
                      ))}
                    </Slider>
                  }
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row >
    </div >

  )
}
export default ProductsGalleryInner