import { Col, Container, Modal, Row } from "react-bootstrap";
import Header from "../../comman/header";
import Footer from "../../comman/footer";
import Images from "../../../helpers/images";
import { useEffect, useState } from "react";
import Data from ".././../../helpers/videoGallery.json";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const CSRInitiatives = () => {
  const [videoShow, setvideoShow] = useState(false);
  const videoClose = () => setvideoShow(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const videosShow = (item) => {
    setvideoShow(true);
    setVideoItem(item);
  };
  const [videoGalleryItems, setVideoGalleryItems] = useState([]);
  const [videoItem, setVideoItem] = useState({});

  useEffect(() => {
    setVideoGalleryItems(Data);
  }, []);

  // for lang change
  const { t } = useTranslation();

  const htmlDecode = (content) => {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  return (
    <>
      <CommonMeta
        title="CSR Initiatives for a Sustainable Tomorrow - Poddar Pigments Limited"
        description="Discover Poddar Pigments Limited's commitment to Corporate Social Responsibility (CSR) initiatives aimed at uplifting communities and fostering sustainable development. Explore our projects that are making a positive impact on society in various fields like education, environment sustainability, health, and more."
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="CSR Initiatives"
        type="article"
      />
      <Header />
      <div className="top-banner-image about-tabs csr-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1> {t("CSR Initiatives")} </h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/about-us">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("About-Us")}</h4>
                      ) : (
                        <>
                          <h4>{t("About-Us")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/the-company">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("The Company")}</h4>
                      ) : (
                        <>
                          <h4>{t("The Company")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/board-members">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Board Members")}</h4>
                      ) : (
                        <>
                          <h4> {t("Board Members")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/locations">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Locations")}</h4>
                      ) : (
                        <>
                          <h4>{t("Locations")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("CSR Initiatives")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="better-tomorrow-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">
                <h2> {t("For A Better Tomorrow")}</h2>
              </div>
              <p> {t("BetterTomorrowDesc1")}
              </p>
              <p>
                {t("BetterTomorrowDesc2")}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="csr-video-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">
                <h2 className="bullet">{t("Our CSR Journey")} </h2>
                <div className="csr-video-image-wrapper">
                  {/* <img
                    loading="lazy"
                    src={Images.comingSoon}
                    className="img-fluid"
                    alt=""
                  /> */}
                </div>
                <div className="video-gallery-box csr-video-image-wrapper">
                  <div className="video-gallery-box-inner">
                    <div className="video-image" onClick={handleShow}>
                      <img
                        loading="lazy"
                        src={Images.CSRVideo}
                        className="img-fluid"
                        alt=""
                      />
                      <div className="play-image">
                        <img
                          loading="lazy"
                          src={Images.Play}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div className="video-gallery">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">
                <h2 className="bullet"> {t("Video Gallery")}</h2>
              </div>
            </Col>
            <div className="video-tab-wrapper">
              {videoGalleryItems.length > 0 &&
                videoGalleryItems.map((item, i) => (
                  <div className="video-gallery-box" key={i}>
                    <div className="video-gallery-box-inner">
                      <div
                        className="video-image"
                        onClick={() => videosShow(item)}
                      >
                        <img
                          loading="lazy"
                          src={`${item.images}`}
                          className="img-fluid"
                          alt=""
                        />
                        <div className="play-image">
                          <img
                            loading="lazy"
                            src={Images.Play}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                      <h4 className="title">{t(`${item?.name}`)}</h4>
                      <p dangerouslySetInnerHTML={{ __html: t(`${item?.desc}`) }} ></p>
                    </div>
                    <div className="link-btn">
                      <span
                        onClick={() => videosShow(item)}
                        className="animated-button"
                      >
                        {t("Find out more")} <i className="icon-next"></i>
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </Row>
        </Container>

        <Modal
          className="video-gallery-modal"
          show={videoShow}
          onHide={videoClose}
          size="lg"
          centered
        >
          <div className="video-close" onClick={videoClose}>
            <img
              loading="lazy"
              src={Images.closeBlack}
              className="img-fluid"
              alt=""
            />
          </div>
          <Modal.Body>
            {videoItem?.hasVideo ? (
              <iframe
                width="100%"
                height="400"
                src={videoItem?.video_url}
                title="csr-6"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ) : (
              <img
                width={"100%"}
                loading="lazy"
                src={`${videoItem.images}`}
                className="img-fluid"
                alt=""
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          className="csr-journey-video"
          size="lg"
          centered
          show={show}
          onHide={videoClose}
        >
          <div className="video-close" onClick={handleClose}>
            <img
              loading="lazy"
              src={Images.closeBlack}
              className="img-fluid"
              alt=""
            />
          </div>
          <Modal.Body>
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/VG8REoaZCLI?si=X4j431rPdfCKklKn"
              title="Our CSR Journey"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Modal.Body>
        </Modal>
      </div >
      <Footer />
    </>
  );
};
export default CSRInitiatives;

