import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie-player";
import worldMapAnimation from "../../../../helpers/worldMapAnimation.json";
import worldMapAnimationDark from "../../../../helpers/worldMapAnimationDark.json";
import { useEffect, useRef,useContext } from "react";
import useOnScreenHook from "../../../../helpers/useOnScreenHook";
import { ThemeContext } from "../../../../context/themeContext";
import { useTranslation } from "react-i18next";

const GlobalPresence = () => {
  const mapRef = useRef(null);
  const lottieRef = useRef(null);
  const isVisible = useOnScreenHook(mapRef);

  useEffect(() => {
    if (lottieRef && !isVisible) {
      lottieRef?.current?.stop();
    }
  }, [isVisible]);
  const { toggle, toggleFunction } = useContext(ThemeContext);
  // language change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <div className="global-presence-section">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="heading-text">
              <h4>{t("globalSec.title")}</h4>
              <p>{t("globalSec.desc")}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} ref={mapRef}>

              <div className="world-map-section">
                <Lottie
                  ref={lottieRef}
                  loop={false}
                  controls
                  animationData={toggle === "light" ? worldMapAnimation : worldMapAnimationDark}
                  play={isVisible}
                />
              </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default GlobalPresence;
