import { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../../../helpers/images";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const [showVideo, setShowVideo] = useState(false);
  const closeVideo = () => setShowVideo(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="about-us-section" id="AboutUs">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="heading-text about-section">
                <h4>{t("aboutUs.title")}</h4>
                <p>
                  {t("aboutUs.description")}
                  <span>
                    <Link to="/about-us">{t("aboutUs.readMore")}</Link>
                  </span>
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card-box-wrapper">
                <div className="card-box">
                  <Link to="/the-company">
                    <div className="overlay"></div>
                    <div className="card-box-inner">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.theCompany}
                          className="img-fluid icon-close"
                          alt="img"
                        />
                      </div>
                      <div className="card-body-text">
                        <p> {t("aboutUs.option1")}</p>
                        <span className="read-more">
                          {t("aboutUs.optionBtn")} {`>`}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div className="cricle-icon"></div>
                </div>
                <div className="card-box">
                  <Link to="/board-members">
                    <div className="overlay"></div>
                    <div className="card-box-inner">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.boardMembers}
                          className="img-fluid icon-close"
                          alt="img"
                        />
                      </div>
                      <div className="card-body-text">
                        <p> {t("aboutUs.option2")}</p>
                        <span className="read-more">
                          {t("aboutUs.optionBtn")} {`>`}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div className="cricle-icon"></div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="border-section"></div>
            </Col>
            <Col lg={6}>
              <div className="card-box-wrapper">
                <div className="card-box">
                  <Link to="/csr-initiatives">
                    <div className="overlay"></div>
                    <div className="card-box-inner">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.csr}
                          className="img-fluid icon-close"
                          alt="img"
                        />
                      </div>
                      <div className="card-body-text">
                        <p> {t("aboutUs.option3")}</p>
                        <span className="read-more">
                          {t("aboutUs.optionBtn")} {`>`}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div className="cricle-icon-top"></div>
                </div>
                <div className="card-box">
                  <Link to="/locations">
                    <div className="overlay"></div>
                    <div className="card-box-inner">
                      <div className="image-section">
                        <img
                          loading="lazy"
                          src={Images.locations}
                          className="img-fluid icon-close"
                          alt="img"
                        />
                      </div>
                      <div className="card-body-text">
                        <p> {t("aboutUs.option4")} </p>
                        <span className="read-more">
                          {t("aboutUs.optionBtn")} {`>`}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div className="cricle-icon-top"></div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card-box-wrapper justify-content-center">
                <div className="card-box home-videos">
                  <div className="home-about-video">
                    <img
                      onClick={() => {
                        setShowVideo(true);
                      }}
                      loading="lazy"
                      src={Images.CVVideo}
                      className="img-fluid video-image"
                      width={"100%"}
                      alt="img"
                    />
                    <div className="video-image-bg"> </div>
                    <img
                      onClick={() => {
                        setShowVideo(true);
                      }}
                      loading="lazy"
                      src={Images.videoPlayWhite}
                      className="play-video"
                      width={"100%"}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        className="youtube-video-section"
        size="xl"
        centered
        show={showVideo}
        onHide={closeVideo}
      >
        <div className="close-btn" onClick={closeVideo}>
          <img
            loading="lazy"
            src={Images.Close}
            className="img-fluid icon-close"
            alt="img"
          />
        </div>
        <Modal.Body>
          <iframe
            width="100%"
            height="100%"
            min-height="500px"
            src="https://www.youtube.com/embed/2qz4X8DDnf0"
            title="About Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AboutUs;
