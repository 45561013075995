import { Col, Container, Row } from "react-bootstrap"
import Images from "../../../helpers/images"
import CountUp from 'react-countup';
import { useRef } from "react";
import useOnScreenHook from "../../../helpers/useOnScreenHook";
import { useTranslation } from "react-i18next";


const WhyChooseUs = () => {
  const counterref = useRef(null)
  const isVisible = useOnScreenHook(counterref)
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <div className="why-choose-us-section">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="heading-text">
              <h4>{t("whyChoose.title")}</h4>
            </div>
          </Col>
        </Row>
        <Row ref={counterref}>
          <Col className="mob-responsive" lg={3}>
            <div className="card-box">
              <div className="card-image">
                <img loading="lazy" src={Images.countries} className='img-fluid' alt='' />
              </div>
              <div className="card-body">
                <h5> {isVisible && <CountUp delay={0} end={30} />}+ </h5>
                <p>{t("whyChoose.subTitle1")}</p>
              </div>
            </div>
          </Col>
          <Col className="mob-responsive" lg={3}>
            <div className="card-box">
              <div className="card-image">
                <img loading="lazy" src={Images.experience} className='img-fluid' alt='' />
              </div>
              <div className="card-body">
                <h5 > {isVisible && <CountUp delay={0} end={30} />}+</h5>
                <p>{t("whyChoose.subTitle2")}</p>
              </div>
            </div>
          </Col>
          <Col className="mob-responsive" lg={3}>
            <div className="card-box">
              <div className="card-image">
                <img loading="lazy" src={Images.color} className='img-fluid' alt='' />
              </div>
              <div className="card-body">
                <h5>{isVisible && <CountUp delay={0} end={60000} />}+</h5>
                <p>{t("whyChoose.subTitle3")}</p>
              </div>
            </div>
          </Col>
          <Col className="mob-responsive" lg={3}>
            <div className="card-box">
              <div className="card-image">
                <img loading="lazy" src={Images.customers} className='img-fluid' alt='' />
              </div>
              <div className="card-body">
                <h5>{isVisible && <CountUp delay={0} end={600} />}+</h5>
                <p>{t("whyChoose.subTitle4")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  )
}
export default WhyChooseUs