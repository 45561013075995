import React from "react";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import CommonMeta from "../../comman/CommonMeta";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
    
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }
  return (
    <>
      <CommonMeta
        title="Leading Masterbatch Manufacturer and Supplier in India"
        description="Explore our comprehensive masterbatch services that include manufacturing, customization, technical support, and a vast color library of over 60,000 shades for diverse applications."
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch,leading masterbatch manufacturing company in India"
        name="about-us"
        type="article"
      />
      <Header />
      <div className="top-banner-image about-tabs services-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1> {t("Services")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/r-and-d-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("R & D")} </h4>
                      ) : (
                        <>
                          <h4> {t("R & D Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/technical-services-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>  {t("Technical")} </h4>
                      ) : (
                        <>
                          <h4>  {t("Technical Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/marketing-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>  {t("Marketing")}</h4>
                      ) : (
                        <>
                          <h4>  {t("Marketing Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/experience-center">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>  {t("Experience")}</h4>
                      ) : (
                        <>
                          <h4>  {t("Experience Center")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/digital-shade-card">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Digital Shade")}</h4>
                      ) : (
                        <>
                          <h4> {t("Digital Shade Card")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("Services")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="service-page-wrapper">
              <h2>
              {t("Unparalleled Quality and Comprehensive Services")}
              </h2>
              <p> {t("We are a prominent masterbatch manufacturing firm in India, renowned for our production of top-notch masterbatches that adhere to rigorous industry norms. Our all-encompassing masterbatch offerings encompass manufacturing, tailored solutions, technical assistance, and an extensive color repository boasting more than 60,000 shades for a wide array of applications.")}
              </p>
              <div className="service-box-wrapper">
                <div className="box">
                  {/* <div className="numbring">
                    <p>1</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5> {t("Manufacturing Excellence")}</h5>
                      <li> {t("Industry leaders with top-quality masterbatches.")}</li>
                      <li>
                         {t("Experts in crafting masterbatches for various applications.")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>2</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5> {t("Rapid Customization")}</h5>
                      <li>
                        <strong> {t("Swift Tailoring :")} </strong>  {t("We customize masterbatches quickly to match your project's exact needs.")}
                      </li>
                      <li>
                        <strong> {t("Expert Support :")} </strong>  {t("Our technical team is ready to assist you promptly.")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>3</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5> {t("Extensive Color Options")}</h5>
                      <li>
                        <strong> {t("Over 60,000 Colors :")}</strong> {t("A vast color library to meet all your color requirements.")}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>4</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5> {t("Fast Delivery & Flexibility")}</h5>
                      <li>
                        <strong> {t("Timely Delivery :")} </strong> {t("We prioritize on-time delivery.")} 
                      </li>
                      <li>
                        <strong> {t("Small Lot Availability :")} </strong> {t("No project is too small for our flexible production.")} 
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="box">
                  {/* <div className="numbring">
                    <p>5</p>
                  </div> */}
                  <div>
                    <ul>
                      <h5> {t("Our Commitment")}</h5>
                      <li>
                        <strong> {t("Consistency :")} </strong> {t("Be assured of reliable quality with every batch.")} 
                      </li>
                      <li>
                        <strong> {t("Environmental Responsibility :")} </strong> {t("We offer eco-friendly solutions.")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default ServicePage;
