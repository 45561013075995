import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Footer from '../../comman/footer';
import Header from '../../comman/header';
import masterbatches from '../../../helpers/masterbatchesForPlasticApplications.json';
import ProductsGalleryInner from './productsGalleryInner';
import useWindowDimensions from '../../../helpers/useWindowDimensions';

const FunctionalAndAdditiveMasterbatches = () => {
  const { t, i18n } = useTranslation();
  const [categoryShow, setCategoryShow] = useState(false);
  const [subCategoryShow, setSubCategoryShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [galleryItems, setGalleryItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const currentTitle = new URLSearchParams(location.search).get('title');
  const currentSubTitle = new URLSearchParams(location.search).get('sub_title');
  const [subCategoryDetails, setSubCategoryDetails] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      if (currentTitle && currentSubTitle) {
        const mainCategory = masterbatches?.find((item) => item.title === currentTitle);
        if (mainCategory) {
          const subcategory = mainCategory?.sub_category?.find((item) => item.name === currentSubTitle);
          setSubCategoryDetails(subcategory?.deatils);
        }
      }
    };

    getDetails();

    return () => {};
  }, []);

  const categoryToggle = (item) => {
    setSelectedCategory(item);
    setCategoryShow(false);
    setSubCategories(item.sub_category);
    setTimeout(() => {
      setCategoryShow(true);
    }, 400);
  };

  const subCategoryToggle = (item) => {
    if (item.sub_category && item.sub_category.length > 0) {
      setSubCategoryShow(true);
    } else {
      handleItemClick(item);
    }
  };

  useEffect(() => {
    setCategories(currentData);
    document.onclick = function (e) {
      const child = e.target;
      const parent = document.getElementById('sidebar');
      if (parent) {
        const check = parent.contains(child);
        if (!check) {
          setCategoryShow(false);
          setSubCategoryShow(false);
        }
      }
    };
  }, [currentData]);

  const handleItemClick = (item, catItem = null) => {
    if (catItem) {
      setSelectedCategory(catItem);
      setGalleryItems(catItem);
    } else {
      if (!selectedCategory) {
        setSelectedCategory(item);
      }
      setGalleryItems(selectedCategory ? selectedCategory : item);
    }
    setSelectedItem(item);
    setCategoryShow(false);
    setSubCategoryShow(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const currentLanguage = i18n.language;
    const translatedData = getSelectedLanguageTranslation(currentLanguage);
    setCategories(translatedData);

    if (translatedData.length > 0) {
      const category = searchParams.get('c');
      const subcategory = searchParams.get('sc');

      if (category) {
        const catItem = translatedData.find((item) => item.title === category);
        if (catItem !== undefined) {
          const subCategoryOutput = catItem.sub_category.find((item) => item.name === subcategory);
          if (subCategoryOutput !== undefined) {
            handleItemClick(subCategoryOutput, catItem);
          }
        }
      } else {
        handleItemClick(translatedData[0]);
      }
    }
  }, [i18n.language]);

  const getSelectedLanguageTranslation = (currentLanguage) => {
    switch (currentLanguage) {
      case 'en':
        return require('.././../../helpers/productLangData/functionalAdditive/en.json');
      case 'es':
        return require('.././../../helpers/productLangData/functionalAdditive/es.json');
      case 'de':
        return require('.././../../helpers/productLangData/functionalAdditive/de.json');
      case 'fr':
        return require('.././../../helpers/productLangData/functionalAdditive/fr.json');
      case 'pt':
        return require('.././../../helpers/productLangData/functionalAdditive/pt.json');
      default:
        return require('.././../../helpers/productLangData/functionalAdditive/en.json');
    }
  };

  // for lang change
  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  const { width } = useWindowDimensions();

  return (
    <>
      <Header />
      <div className="product-wrapper-section">
        <Row className="g-0">
          <div className="product-category-wrapp-main">
            <div className="sidebar-sec-ineer">
              <div className="man-made-fibre-category-filter functional-and-additive-masterbatche-wrapp" id="sidebar">
                <div className="category-title">
                  <h4> {t('Functional and Additive Masterbatches')}</h4>
                </div>
                <ul>
                  {categories.map((item, i) => (
                    <div key={i}>
                      <li>
                        <div onClick={() => categoryToggle(item)}>
                          <i className="icon icon-polymide_default"></i>
                          <div className="cat-item">{t(`${item.title}`)}</div>
                        </div>
                        <div className={`categoryShow ${categoryShow ? 'visible' : 'hidden'}`}>
                          {subCategories.length > 0 && (
                            <ul>
                              {subCategories.map((subCategory, j) => (
                                <div key={j}>
                                  <li>
                                    <span onClick={() => subCategoryToggle(subCategory)}>{t(`${subCategory.name}`)}</span>
                                  </li>
                                </div>
                              ))}
                            </ul>
                          )}
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
            <div className="product-details m-3">
              <div className="product-head-button">
                <Link to="/masterbatch-for-man-made-fiber" className="product-btn animated-button">
                  {t('Explore Masterbatch for Man-Made Fiber')}
                  <i className="icon-next"></i>
                </Link>
                <Link to="/masterbatches-for-plastic-applications" className="product-btn animated-button">
                  {t('Explore Masterbatches for Plastic Application')}
                  <i className="icon-next"></i>
                </Link>
              </div>
              <div className="product-details-images-section">
                <div className="product-image">
                  <img width={'100%'} loading="lazy" src={`${selectedItem?.image}`} className="img-fluid" alt="" />
                </div>
                <h3 className="product-title-name">{t(`${selectedItem?.name}`)}</h3>
              </div>
              <ProductsGalleryInner items={galleryItems} selectedItem={selectedItem} handleItemClick={(item) => handleItemClick(item)} />
              <div className="breadcrumb-wrapper product-breadcrumb">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/">{t('Home')}</Link>
                          </li>
                          <li className="breadcrumb-item  active">
                            {isMobile ? (
                              <>{t('functional And Additive')}</>
                            ) : (
                              <>{t('Functional and Additive Masterbatches')}</>
                            )}
                          </li>
                        </ol>
                      </nav>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="mt-4 polyester_category_wrapper" dangerouslySetInnerHTML={{ __html: selectedItem?.desc }}></div>
            </div>
          </div>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default FunctionalAndAdditiveMasterbatches;
