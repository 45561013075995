import React, { createContext, useEffect, useState } from "react";
import i18n from "../i18n";
// Context has been created
const LanguageContext = createContext(false);
// Provider
const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    useEffect(() => {
        const defLanguage = localStorage.getItem('i18nextLng')? localStorage.getItem('i18nextLng') : 'en';
        changeLanguage(defLanguage)
    }, [])

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
export { LanguageContext, LanguageProvider };