import { Col, Nav, Row, Tab } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const ProductsGallery = ({ selectedSubCategory, activeSubCategory }) => {

  const [defaultkey] = useState('subcategory-1');
  
  var settings = {
    dots: false,
    arrow: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 3.5,
    autoplay: false,
    // autoplaySpeed: 2000,
    slidesToScroll: 3,
    centerMode: false,
    // centerPadding: '50px',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScrol1: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };
  // for lang change
  const { t } = useTranslation();
  
  return (
    <div className="product-category-section">
      <Row className="g-0">
        <div className="product-category-section-wrapper">
          <div className="product-category-left"></div>
          <div className="product-category-right">
            <div className="product-category-tab product-category-tab-home">
              <Tab.Container id="left-tabs-example" defaultActiveKey={activeSubCategory}>
                <Row className="g-0">
                  {
                    isMobile ? (
                      <div>
                        {/* className={`${selectedItem?.white_heading ? 'white' : ''} product-title-name`}>{selectedItem?.name} */}
                        <Col lg={12}>
                          {selectedSubCategory?.length > 1 ? (
                            <div className="mobile-category">
                              <Nav variant="pills" className="">
                                {selectedSubCategory?.map((item, i) => (
                                  <Nav.Item key={i}>
                                    <Nav.Link eventKey={`subcategory-${item.id}`}><i className="icon icon-polymide_default"></i>{t(`${item?.sub_category_name}`)} </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                            </div>
                          ) : <div className="home-product-glry-spacing" >
                          </div>}
                        </Col>
                      </div>
                    ) : (
                      <Col lg={12}>
                        {selectedSubCategory?.length > 1 ? (
                          <Nav variant="pills" className="">
                            {selectedSubCategory?.map((item, i) => (
                              <Nav.Item key={i}>
                                <Nav.Link eventKey={`subcategory-${item.id}`}><i className="icon icon-polymide_default"></i> {t(`${item?.sub_category_name}`)}</Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        ) : <div className="home-product-glry-spacing" >
                        </div>}
                      </Col>
                    )
                  }
                  <Col lg={12}>
                    {selectedSubCategory?.length > 1 ? (
                      <Tab.Content>
                        {selectedSubCategory?.map((item, i) => (
                          <Tab.Pane key={i} eventKey={`subcategory-${item.id}`}>
                            <Slider {...settings}>
                              {item.details?.map((detail, i) => (
                                <div key={i} className="slider-box-wrapper">
                                  <div className="effect-lily">
                                    <Link to={`${detail?.url}?c=${encodeURIComponent (item?.sub_category_name)}&sc=${encodeURIComponent(detail?.title)}&smp=${detail?.smart ? '1' : '0'}`}>
                                      <div className="image-overlay"></div>
                                      <div className="image-section">
                                        <div className="img-overlay"></div>
                                        <img loading="lazy" src={`${detail.image}`} className='img-fluid' alt='' />
                                      </div>
                                      <div className="gallery-slider-text">
                                        <div className="gallery-slider-text-inner">
                                          <h5>{t(`${detail?.title}`)}</h5>
                                          
                                          <div className="read-more-link"><span>{t("more")} {'>'}</span></div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    ) :
                      <>
                        {selectedSubCategory?.map((item) => (
                          <Slider {...settings}>
                            {item.details?.map((detail, i) => (
                              <div key={i} className="slider-box-wrapper">
                                <div className="effect-lily">
                                  <Link to={`${detail?.url}?c=${encodeURIComponent(item?.sub_category_name)}&sc=${encodeURIComponent(detail?.title)}&smp=${detail?.smart ? '1' : '0'}`}>
                                    <div className="image-overlay"></div>
                                    <div className="image-section">
                                      <div className="img-overlay"></div>
                                      <img loading="lazy"  src={`${detail.image}`} className='img-fluid' alt='' />
                                    </div>
                                    <div className="gallery-slider-text">
                                      <div className="gallery-slider-text-inner">
                                        <h5>{t(`${detail?.title}`)}</h5>
                                        <div className="read-more-link"><span>{t("more")} {'>'}</span></div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        ))}
                      </>
                    }
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Row >
    </div >

  )
}
export default ProductsGallery