import React from 'react';
import AppRoutes from './appRoutes';
import { ThemeProvider } from './context/themeContext';
import { LanguageProvider } from './context/LanguageContext';
import ScrollToTopRoute from './helpers/ScrollToTop';
import ScrollToTop from "react-scroll-to-top";
import { ToastProvider } from 'react-toast-notifications';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';



const App = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatClick = () => {
    setIsChatOpen(!isChatOpen);
  };
  return (
    <>
    <HelmetProvider>
      <ThemeProvider>
        <LanguageProvider>
          <ToastProvider>
            <ScrollToTopRoute />
            <AppRoutes />
            <div className='scroll-top'>
              <ScrollToTop smooth color="#fff" />
            </div>
            <Toaster />
          
          </ToastProvider>
        </LanguageProvider>
      </ThemeProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
