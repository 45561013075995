import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import Images from "../../../helpers/images";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import { isMobile } from "react-device-detect";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const MarketingSupport = () => {
  const { width } = useWindowDimensions();
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <CommonMeta
        title="Poddar Pigments Ltd: Textile Industry Experts & Masterbatch Solutions"
        description=" Explore PPL's knowledge-based marketing approach and the support of industry experts. Elevate your products with international quality, quick solutions, and innovative masterbatch solutions."
        keywords="Textile Industry Experts, masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Marketing Support"
        type="article"
      />
      <Header />
      <div className="top-banner-image marketing-support-services-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1>{t("Marketing Support")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/r-and-d-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("R & D Support")}</h4>
                      ) : (
                        <>
                          <h4>{t("R & D Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/technical-services-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Technical Support")}</h4>
                      ) : (
                        <>
                          <h4> {t("Technical Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/experience-center">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Experience Center")}</h4>
                      ) : (
                        <>
                          <h4> {t("Experience Center")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/digital-shade-card">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Digital Shade Card")}</h4>
                      ) : (
                        <>
                          <h4>{t("Digital Shade Card")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {t("Marketing Support")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-desc-section">
        <Container>
          <Col lg={12}>
            <div className="heading-text">
              {/* <h2>Marketing Support</h2> */}
            </div>
          </Col>
          <Row className=" desc-box-wrap mb-5 mobile-order">
            <Col lg={6}>
              <p> {t("At Poddar Pigments Limited (PPL), we take immense pride in our distinguished position within the textile industry. We have the largest fleet of experts compared to any other masterbatch company, and our seasoned professionals bring a deep understanding of textile intricacies, innovative thinking, and years of expertise to deliver exceptional masterbatch solutions.")}</p>
              <h5>{t("Comprehensive Expertise")}</h5> 
              <ul>
                <li><strong>{t("Textile Industry Focus :")}  </strong>
                {t("We are the leaders in providing solutions for the textile sector.")}
                </li>
                <li><strong>{t("Cross-Industry Knowledge :")} </strong>{t("We have experts from various fields for diverse applications.")}
                </li>
              </ul>
            </Col>
            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.ms5}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.ms1}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={12}>
            </Col>
          </Row>
          <Row className=" mobile-order">
            <Col className="order2" lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.ms4}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.ms2}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={6} className="order1">
              <div className="desc-box">
              <h5> {t("The Complete Package")}</h5>
                <ul>
                
                  <li><strong>{t("Quality and Cost-Competitiveness :")} </strong>{t("We prioritize both product quality and pricing.")}
                  </li>
                  <li><strong> {t("Intellect and Understanding :")} </strong>
                  {t("Our team has an in-depth understanding of products, manufacturing processes, applications, and market dynamics.")}
                  </li>
                </ul>
                <h5>{t("Knowledge-Based Marketing")}</h5>
                <ul>
                  <li><strong>  {t("Collective Expertise :")} </strong>
                  {t("We can leverage our team's knowledge to make informed decisions.")}
                  </li>
                  <li><strong>{t("Customer Empowerment :")} </strong>
                  {t("We educate and empower clients to make better choices.")}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="marketing-box">
          <Col lg={12}>
          <h5> {t("Proactive Collaboration")}</h5>
            <ul>
              <li><strong> {t("Market Exploration :")}</strong>
              {t("We actively engage and explore new applications and markets.")}
              </li>
              <li><strong> {t("Innovation and Tailor-Made Solutions :")}</strong>
              {t("We continuously innovate to propel your products to new heights.")}
              </li>
            </ul>
            </Col>
            </Row>
            <Row>
            <Col lg={6}>
              <div>
                <h5> {t("With PPL, You Gain :")}</h5>
                <ul>
                  <li> {t("International Quality Products")}</li>
                  <li>{t("Consistency in Performance")}</li>
                  <li>  {t("Innovative and Agile Solutions")}</li>
                  <li> {t("Swift Delivery")}</li>
                  <li> {t("Small Lot Availability")}</li>
                  <li> {t("Reduced Inventory")}</li>
                  <li>{t("Custom Colors and Additives ")}</li>
                  <li> {t("Responsive Technical Support")}</li>
                  <li> {t("Solution-Driven Approach")}</li>
                  <li> {t("Extensive Color Library (60,000+ Colors)")}</li>
                </ul>
              </div>
            </Col>
            <Col lg={6}>
              <div className="image-box marketing-box3">
                <img
                  loading="lazy"
                  src={Images.ms3}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.ms6}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="faq-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">
                <h2>FAQ</h2>
              </div>
            </Col>
            <Col lg={12}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Item #1</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div> */}
      <Footer />
    </>
  );
};
export default MarketingSupport;
