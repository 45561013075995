import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getReports as getReportsApi } from "../../../helpers/api";
import InlineLoader from "../../comman/InlineLoader";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const InvestorRelations = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    getReports();
  }, []);

  const getReports = async () => {
    try {
      const { data } = await getReportsApi();
      setReports(data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
    
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }
  return (
    <>
      <CommonMeta
        title="Poddar Pigments Ltd: Decade of Growth, Financials, CSR, & Morea"
        description="Poddar Pigments Ltd: Transparent Financials, CSR, Shareholder Info, and Stock Exchange Compliance – Your Trusted Investor Resource"
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Investor Relations"
        type="article"
      />
      <Header />
      <div className="top-banner-image investor-relations-bg"></div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Investor Relations")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="investor-relations-wrapper">
        <Container>
          <Row>
            <Col> 
              <p> {t("As a pioneering force in the masterbatch industry, we've been coloring the world with innovation and excellence for decades. Recognizing the importance of maintaining trust, we prioritize open dialogue and strong ties with our valued investor community. This platform is meticulously designed to provide you with a comprehensive suite of resources from our latest financial performance summaries to in-depth analyses of our market strategies. Alongside our rich legacy, we are steadfast in our commitment to transparency, value creation, and sustainable growth. By ensuring our stakeholders remain informed and confident, we aim to cultivate a partnership that thrives on mutual success. Dive in to explore our financial journey, witness our dedication to responsible business practices, and be a part of our vibrant and colorful trajectory towards continued excellence.")}
              </p>
            </Col>
          </Row>
          <Row>
            <InlineLoader loading={loading} />
            {!loading &&
              reports.map((item, i) => (
                <div key={`reports${i}`}>
                  <div className="card-box-wrapper">
                    <div className="card-heading">
                      <h4>{t(`${item._id.relationType}`)}</h4>
                    </div>
                    <div
                      className={`card-body-wrapper ${
                        item?.reports?.length < 3 ? "no-wrapper-top-border" : ""
                      } 
                      ${item?.reports?.length === 5 ? "five-item" : ""}
                      ${item?.reports?.length === 8 ? "eight-item" : ""}`}
                    >
                      {item?.reports?.map((report, j) => (
                        <div className="card-body">
                          <div className="card-box">
                            <div key={j}>
                              <Link
                                to={`/investor-relations/details/${encodeURI(
                                  item._id.relationType
                                )}/${encodeURI(report.title)}`}
                              >
                                <h5> {t(`${report?.title}`)} </h5>
                                <div className="icon">
                                  <i className="icon-next"></i>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default InvestorRelations;
