import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Images from "../../../helpers/images"
import { useTranslation } from "react-i18next";


const InvestorRelations = () => {
  // for lang change
  const { t } = useTranslation();
  
  return (
    <div className="investor-relations-section" id="InvestorRelations">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="heading-text">
              <h4>{t("investorSec.title")}</h4>
              <p>{t("investorSec.desc")}</p>
            </div>
          </Col>
        </Row>
        <Row className="mob-head-box-wrap">
          <Col className="box-wrapper" lg={2}>
            <div className="investor-relations-box">
              <div className="investor-relations-image">
                <img loading="lazy" src={Images.financials} className='img-fluid' alt='' />
                <div className="card-body">
                  <div className="title">{t("investorSec.box1.title")}</div>
                  <div className="read-more-link"><Link to='/investor-relations'>{t("investorSec.readMore")} <i className="icon icon-next"></i></Link> </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="box-wrapper"  lg={2}>
            <div className="investor-relations-box">
              <div className="investor-relations-image">
                <img loading="lazy" src={Images.shareholdersInformation} className='img-fluid' alt='' />
                <div className="card-body">
                  <div className="title">{t("investorSec.box2.title")}</div>
                  <div className="read-more-link"><Link to='/investor-relations'>{t("investorSec.readMore")} <i className="icon icon-next"></i></Link> </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="box-wrapper" lg={2}>
            <div className="investor-relations-box">
              <div className="investor-relations-image">
                <img loading="lazy" src={Images.codesPolicies} className='img-fluid' alt='' />
                <div className="card-body">
                  <div className="title">{t("investorSec.box3.title")} </div>
                  <div className="read-more-link"><Link to='/investor-relations'>{t("investorSec.readMore")}<i className="icon icon-next"></i></Link> </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="box-wrapper" lg={2}>
            <div className="investor-relations-box">
              <div className="investor-relations-image">
                <img loading="lazy" src={Images.csrHome} className='img-fluid' alt='' />
                <div className="card-body">
                  <div className="title">{t("investorSec.box4.title")}</div>
                  <div className="read-more-link"><Link to='/investor-relations'>{t("investorSec.readMore")} <i className="icon icon-next"></i></Link> </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="box-wrapper" lg={2}>
            <div className="investor-relations-box">
              <div className="investor-relations-image">
                <img loading="lazy" src={Images.stckExchange} className='img-fluid' alt='' />
                <div className="card-body">
                  <div className="title">{t("investorSec.box5.title")}</div>
                  <div className="read-more-link"><Link to='/investor-relations'>{t("investorSec.readMore")}<i className="icon icon-next"></i></Link> </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="box-wrapper" lg={2}>
            <div className="investor-relations-box">
              <div className="investor-relations-image">
                <img loading="lazy" src={Images.investorEductaion} className='img-fluid' alt='' />
                <div className="card-body">
                  <div className="title">{t("investorSec.box6.title")}</div>
                  <div className="read-more-link"><Link to='/investor-relations'>{t("investorSec.readMore")} <i className="icon icon-next"></i></Link> </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  )
}
export default InvestorRelations