import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getStockPrice as getStockPriceApi } from "./../../helpers/api";
import SideBar from "./sideBar";
import Images from "../../helpers/images";
import { ThemeContext } from "../../context/themeContext";
import { LanguageContext } from "../../context/LanguageContext";

const NonStickyHeaderActions = ({ sidebarToggle, setSidebarToggle }) => {

  const { changeLanguage } = useContext(LanguageContext);
  
  const { toggle, toggleFunction } = useContext(ThemeContext);
  const [stock, setStock] = useState({});
  const [lastChecked, setLastChecked] = useState(localStorage.getItem("PODDAR_STOCK_TIME"));
  const defLanguage = localStorage.getItem('i18nextLng')? localStorage.getItem('i18nextLng') : 'en';

  
  const languages = [
    { code: "en", flag: Images.english },
    { code: "es", flag: Images.spainsh },
    { code: "fr", flag: Images.french },
    { code: "de", flag: Images.german },
    { code: "pt", flag: Images.portugal },
  ];

  let defaultLang = languages.find(Item=> Item.code == defLanguage);
  if(defaultLang == undefined){
    defaultLang = languages[0];
  }

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang);

  useEffect(() => {
    if (lastChecked) {
      var date = moment(lastChecked);
      var hoursDiff = moment().diff(date, "hours");
      if (hoursDiff < 3) {
        const storedStock = JSON.parse(localStorage.getItem("PODDAR_STOCK_DATA"));
        setStock(storedStock);
        return;
      }
    }
    getShareRate();
  }, []);

  const getShareRate = async () => {
    try {
      const { data } = await getStockPriceApi();
      setStock(data.data);
      localStorage.setItem("PODDAR_STOCK_TIME", moment().toString());
      localStorage.setItem("PODDAR_STOCK_DATA", JSON.stringify(data.data));
    } catch (error) {}
  };

  const changeLang = (language) => {
    setSelectedLanguage(language);
    changeLanguage(language.code);
  };

  return (
    <>
      <div className="header-right-section">
        {stock && (
          <a
            href="https://www.nseindia.com/get-quotes/equity?symbol=PODDARMENT"
            target="_blank"
            rel="noopener noreferrer"
            className={`btn-section stock-wrapper ${
              stock?.diffrenceType === "+" ? "positive" : "negative"
            }`}
          >
            <img
              loading="lazy"
              src={
                stock?.diffrenceType === "+" ? Images.Arrow : Images.ArrowRed
              }
              className="img-fluid me-1"
              alt=""
            />
            NSE {stock?.currentPrice}{" "}
            <span
              className={`percentage ${
                stock?.diffrenceType === "-" ? "positive" : "negative"
              }`}
            >
              (+{stock?.diffrencePercentage}%)
            </span>
          </a>
        )}
        <div className="header-action-wrapper">
          <ul>
            {/* <li>
              <div className="language-section">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-button-drop-centered"
                    drop="down-centered"
                    className="lang-drop-btn"
                    size="sm"
                  >
                    <img
                      loading="lazy"
                      src={selectedLanguage.flag}
                      className="img-fluid"
                      alt=""
                    />
                    <span>{selectedLanguage.code.toUpperCase()}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="lang-menu dropdown-menu-sm">
                    {languages.map((language) => (
                      <Dropdown.Item
                        key={language.code}
                        className="language-options"
                        onClick={() => changeLang(language)}
                      >
                        <img loading="lazy" src={language.flag} className="img-fluid" alt="" />
                        <span>{language.code.toUpperCase()}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li> */}
            <li>
              {toggle === "light" ? (
                <i className="theme-icon icon-dark_mode" onClick={toggleFunction}></i>
              ) : (
                <i className="theme-icon icon-light_mode" onClick={toggleFunction}></i>
              )}
            </li>
            <li>
              <i
                className="theme-icon icon-ham_burger"
                onClick={(e) => {
                  setSidebarToggle((prv) => !prv);
                }}
              ></i>
            </li>
          </ul>
        </div>
      </div>
      <SideBar show={sidebarToggle} handleClose={setSidebarToggle} />
    </>
  );
};

export default NonStickyHeaderActions;
