import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import Images from "../../../helpers/images";
import { Link } from "react-router-dom";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const People = () => {
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }
  return (
    <>
      <CommonMeta
        title="People Sustainability Initiatives by Poddar Pigments Ltd"
        description="Poddar Pigments Ltd: Prioritizing People Sustainability through Health & Safety Initiatives, Medical Camps, and Community Engagement"
        keywords="People Sustainability, masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="People"
        type="article"
      />
      <Header />
      <div className="top-banner-image sustainability-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1> {t("Sustainability")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper sustainbility-tab">
              <div className="card-box-wrapper">
                <Link to="/sustainability/process">
                  <div className="card-box">
                    <div className="name">
                      <h4> {t("Process")}</h4>
                      <div className="icon">
                        <i className="icon-next_arrow"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/sustainability/product">
                  <div className="card-box">
                    <div className="name">
                      <h4> {t("Product")}</h4>
                      <div className="icon">
                        <i className="icon-next_arrow"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("People")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sustainable-wrap">
        <Container>
          <Row className="heading-text">
            <h2>{t("People Sustainability")}</h2>
            <p>{t("As a market leader in masterbatch manufacturing in India, we are committed to people sustainability, and fostering well-being, equity, and resilience in individuals and communities. Our approach addresses education, healthcare, fair employment, mental health, and cultural preservation, creating a thriving and inclusive workspace. At Poddar Pigments Limited, health and safety are considered foundational to sustainable growth. Our comprehensive safety measures including de-dusting systems, fire safety, and health camps, ensure a safe and productive workplace.")}
            </p>
            <h5> {t("Empowering Lives and Communities")}</h5>
            <p> {t("People sustainability is central to our mission. We focus on fostering the well-being, equity, and resilience of individuals and communities. Our commitment goes beyond being a masterbatches manufacturer in India; it extends to creating thriving and inclusive societies.")}
            </p>
            <h5> {t("Supporting Human Development")}</h5>
            <p> {t("We address fundamental needs like widespread access to education and healthcare. Our aim is to contribute to human development and ensure a high quality of life for our employees and the communities we serve.")}
            </p>
            <h5> {t("Promoting Fair Employment Opportunities")}</h5>
            <p> {t("We provide fair employment opportunities, fostering social cohesion and diversity in our workforce.")}
            </p>
            <h5> {t("Prioritizing Mental Health and Well-being")}</h5>
            <p> {t("People’s mental and emotional health are paramount to our values. Our workplace promotes a healthy work-life balance and supports the mental well-being of our employees.")}
            </p>
            <h5> {t("Preserving Culture and Diversity")}</h5>
            <p> {t("Cultural preservation is essential. We celebrate diversity and actively work to preserve cultural heritage in the regions we serve.")}</p>
          </Row>
          <Row>
            <div className="people-list">
              <h2> {t("Health & Safety - Foundations of Sustainable Growth")}</h2>
              <div>
                <h5> {t("Key Features :")}</h5>
                <ul>
                  <li>
                    <strong> {t("Effective De-dusting System :")} </strong> {t("We maintain a dust-free environment, which is crucial for health and safety.")} 
                  </li>
                  <li>
                    <strong> {t("Fire Safety Measures :")} </strong> {t("Our comprehensive fire safety measures ensure the safety of our workforce.")}
                  </li>
                  <li>
                    <strong> {t("Medical Health Camps :")} </strong> {t("Regular health check-ups and preventive healthcare measures for employees are undertaken for consistent well-being.")}
                  </li>
                  <li>
                    <strong> {t("Safety Committee Meetings :")} </strong> {t("Regular meetings involve key stakeholders in ensuring a safe work environment.")} 
                  </li>
                </ul>
              </div>
            </div>
          </Row>
          <Row className="align-items-center heading-text-wrapp ">
            <Col lg={7}>
              <div className="heading-text">
                <h2> {t("Creating Safe and Healthy Workspaces")} </h2>
                <p> {t("At Poddar Pigments Limited, health and safety are important for sustainable growth. We prioritize an engaged, happy workforce and healthy, safe workspaces.")}
                </p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="sustainable-living-wrapper">
                <div className="image-section-wrapper">
                  <div className="image-box box1">
                    <img
                      loading="lazy"
                      src={Images.HealthSafety1}
                      className="img-fluid img-1"
                      alt=""
                    />
                    <img
                      loading="lazy"
                      src={Images.HealthSafety3}
                      className="img-fluid img-2"
                      alt=""
                    />
                  </div>
                  <div className="image-box">
                    <img
                      loading="lazy"
                      src={Images.HealthSafety2}
                      className="img-fluid img-3"
                      alt=""
                    />
                    <img
                      loading="lazy"
                      src={Images.HealthSafety4}
                      className="img-fluid img-4"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center heading-text-wrapp mobile-view">
            <Col lg={5}>
              <div className="image-box1">
                <img
                  loading="lazy"
                  src={Images.dust}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={7}>
              <div className="heading-text">
                <h2>Effective De-dusting System</h2>
                <p>
                  Our de-dusting system ensures a dust-free environment, which
                  is especially vital, as powders are key components of our raw
                  materials.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center heading-text-wrapp">
            <Col lg={7}>
              <div className="heading-text sustain-people">
                {/* <h2>Dust</h2> */}
                <h2>Comprehensive Fire Safety Measures</h2>
                <p>
                  We conduct regular fire risk assessments, maintain fire
                  detection and suppression systems, provide fire safety
                  training, and establish stringent evacuation plans to ensure a
                  safe workplace.
                </p>
                {/* <div className="list">
                  <h5>Comprehensive Fire Safety Measures</h5>
                  <ul>
                    <li>
                      Conduct regular fire risk assessments to identify
                      potential hazards.
                    </li>
                    <li>
                      Install and maintain fire detection and suppression
                      systems.
                    </li>
                    <li>
                      Train employees in fire safety procedures and provide them
                      with appropriate firefighting equipment.
                    </li>
                    <li>Establish evacuation plans and conduct fire drills.</li>
                    <li>
                      Store hazardous materials safely and in compliance with
                      local regulations.
                    </li>
                    <li>
                      Ensure that electrical systems are up to code and
                      regularly inspected.
                    </li>
                  </ul>
                </div> */}
              </div>
            </Col>
            <Col lg={5}>
              <div className="image-box1">
                <img
                  loading="lazy"
                  src={Images.FireSafty}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center heading-text-wrapp mobile-view">
            <Col lg={5}>
              <div className="image-box1">
                <img
                  loading="lazy"
                  src={Images.healthCamp}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={7}>
              <div className="heading-text">
                {/* <h2>Driving Sustainability</h2> */}
                <h2>Regular Medical Health Camps</h2>
                <p>
                  Our regular health camps offer employees various options for
                  health check-ups, screenings, and other preventive healthcare
                  measures.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center heading-text-wrapp">
            <Col lg={7}>
              <div className="heading-text">
                {/* <h2>Driving Sustainability</h2> */}
                <h2>Safety Committee Meetings</h2>
                <p>
                  Holding regular Safety Committee meetings is integral to
                  maintaining a safe and healthy work environment. These
                  meetings involve our key stakeholders and we discuss safety
                  issues and implement measures based on them.
                </p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="image-box1">
                <img
                  loading="lazy"
                  src={Images.safety_committee}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default People;
