import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Images from "../../helpers/images";
import { Col, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useTranslation,} from 'react-i18next';
const HomeSlider = () => {

    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [totalSlides, setTotalSlides] = useState(0);

    useEffect(() => {
        if (sliderRef.current) {
            const slider = sliderRef.current.innerSlider;
            setTotalSlides(slider.state.slideCount);
            setCurrentSlide(slider.state.currentSlide);
        }
    }, []);

    const handleSlideChange = (currentSlide) => {
        setCurrentSlide(currentSlide);
    };

    var homeSlider = {
        dots: false,
        arrow: true,
        autoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        cssEase: 'ease-in-out',
        touchThreshold: 100,
    }
    const { t } = useTranslation();

    return (
        <div className="slider-section" id="home">
            <Row className="g-0">
                <Col md={12}>
                    <Slider {...homeSlider} ref={sliderRef} afterChange={handleSlideChange}>
                    <div className="slider-box-wrapper">
                            <div className="image-section">
                                <div className="overlay"></div>
                                <img loading="lazy" src={Images.Slider6} className='img-fluid' alt='' />
                            </div>
                            <div className="slider-text">
                                <h2 className="hover-text-effect">{t("banner1.bannerTitle1")} </h2>
                                <h3>{t("banner1.bannerTitle2")} </h3>
                                <h3>{t("banner1.bannerTitle3")}</h3>
                            </div>
                        </div>
                       
                        <div className="slider-box-wrapper">
                            <div className="image-section">
                                <div className="overlay"></div>
                                <img loading="lazy" src={Images.Slider4} className='img-fluid' alt='' />
                            </div>
                            <div className="slider-text ">
                                {/* <h2 className="hover-text-effect">Brilliantly Customized,</h2> */}
                                <h3>{t("banner2.bannerTitle1")} </h3>
                                {/* <h3>The Poddar Advantage</h3> */}
                            </div>
                        </div>
                        <div className="slider-box-wrapper">
                            <div className="image-section">
                                <div className="overlay"></div>
                                <img loading="lazy" src={Images.Slider9} className='img-fluid' alt='' />
                            </div>
                            <div className="slider-text">
                                {/* <h2 className="hover-text-effect">Imagination blooms</h2> */}
                                <h3>{t("banner3.bannerTitle1")} <br/>{t("banner3.bannerTitle2")}</h3>
                            </div>
                        </div>

                        <div className="slider-box-wrapper">
                            <div className="image-section">
                                <div className="overlay"></div>
                                <img loading="lazy" src={Images.Slider2} className='img-fluid' alt='' />
                            </div>
                            <div className="slider-text ">
                                <h2 className="hover-text-effect">{t("banner4.bannerTitle1")} </h2>
                                <h3>{t("banner4.bannerTitle2")}</h3>
                            </div>
                        </div>
                    </Slider>
                    <div className="total-slider-count">
                        {currentSlide + 1} / {totalSlides}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default HomeSlider
