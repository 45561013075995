import { Col, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductsGallery from "./productsGallery";
import Data from ".././../../helpers/product.json";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Products = () => {
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    Data[0].sub_category
  );
  const [activeSubCategory, setActiveSubCategory] = useState("subcategory-1");
// for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div className="product-section" id="Products">
      <>
        <Row className="g-0">
          <Col lg={12}>
            <div className="heading-text">
              <h4>{t("products.mainTitle")}</h4>
              <p>
              {t("products.desc1")}
              </p>
              <p>
              {t("products.desc2")}
              </p>
            </div>
          </Col>
          <div className="product-tab">
            <Tab.Container id="left-tabs-example" defaultActiveKey="category-0">
              <div className="product-tab-inner-wrapper">
                <div className="product-tab-label">
                  <div className="product-range-heading">
                    <h4>
                      <i className="theme-icon icon-product_range"></i>
                      {t("products.categoryTitle")}
                    </h4>
                  </div>
                  <Nav variant="pills" className="flex-column">
                    {Data.map((item, i) => (
                      <div key={i}>
                        <Nav.Item>
                          <Nav.Link
                            onClick={() => {
                              setSelectedSubCategory(item?.sub_category);
                              setActiveSubCategory(`subcategory-${i}`);
                            }}
                            eventKey={`category-${i}`}
                          >
                            {t(`${item.category_name}`)}
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    ))}
                  </Nav>
                </div>
                <div className="product-tab-content">
                  <Tab.Content> 
                    
                    {Data.map((item, i) => (
                      <Tab.Pane key={i} eventKey={`category-${i}`}>
                        <div className="product-box">
                          <div className="product-image">
                            <div className="over"></div>
                            <img
                              loading="lazy"
                              src={`${item.image}`}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="product-details">
                            <h4> {t(`${item.title}`)}</h4>
                            <div className="link-btn">
                              <Link className="animated-button" to={item.url}>
                                <i className="theme-icon icon-explore"></i>
                                {t("products.imageBtn")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </Row>
      </>
      <ProductsGallery
        selectedSubCategory={selectedSubCategory}
        activeSubCategory={activeSubCategory}
      />
    </div>
  );
};
export default Products;
