import React, { useRef } from "react";
import Images from "../../../helpers/images";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { chatForm as chatFormApi, chatData as chatDataApi, saveSteps as saveStepsApi } from "../../../helpers/api";
import { showToast, hasError } from "../../../helpers/utils";
import ScrollToTop from "react-scroll-to-top";

function Chat({ setIsChatOpen,isChatOpen }) {
  const [showPopup, setShowPopup] = useState(false);
  const [chats, setChats] = useState([])
  const [chatEnded, setChatEnded] = useState(false);
  const [chatServerData, setChatServerData] = useState([])
  const [validationErrors, setValidationErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChatClick = () => {
    setIsChatOpen(!isChatOpen);
  };
  const chatBottomRef = useRef(null);

  useEffect(() => {
    if (chats.length > 0) {
      sessionStorage.setItem('PODDAR_CHAT_ITEMS', JSON.stringify(chats));
      chatBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chats])


 

  useEffect(() => {

    const chatsItems = sessionStorage.getItem('PODDAR_CHAT_ITEMS')
    const chatId = sessionStorage.getItem('PODDAR_CHAT_ID')
    if (chatsItems && chatId) {
      setChats(JSON.parse(chatsItems))
    } else {

      const data = [
      //   {
      //   'id': 0,
      //   'type': 'TEXT',
      //   'direction': 'LEFT',
      //   'text': 'Hello and thank you for visiting Poddar Pigments.',
      // },
       {
        'id': 0,
        'type': 'TEXT',
        'direction': 'LEFT',
        'text': 'Help us know whom we are talking to',
      }, {
        'id': 0,
        'type': 'FORM',
        'direction': 'LEFT'
      }];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        setChats((prevstate) => ([
          ...prevstate,
          element
        ]))
      }
    }

    loadChatFromServer();

  }, [])

  const handlePopUp = () => {
    sessionStorage.clear()
    setShowPopup(!showPopup)
  }

  const handleOnChange = (name, value) => {
    let errors = validationErrors.filter((err) => err.key !== name);
    setValidationErrors(errors);
    setFormData((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const loadChatFromServer = async () => {
    const { data } = await chatDataApi(formData);
    setChatServerData(data.result);
  }

  const submit = async () => {
    try {
      const { data } = await chatFormApi(formData);
      setLoading(false);
      setFormData({});
      setValidationErrors([]);

      sessionStorage.setItem('PODDAR_CHAT_ID', data.data.id)
      sessionStorage.setItem('PODDAR_CHAT_USER_NAME', data.data.name)

      const chatItems = [...chats]
      const filteredChats = chatItems.filter((item) => item.type !== 'FORM')

      const newData = [{
        'id': 0,
        'type': 'TEXT',
        'direction': 'RIGHT',
        'text': `&lt;p>&lt;strong>Name : &lt;/strong> ${data.data.name}&lt;/p>&lt;p>&lt;strong>Email : &lt;/strong> ${data.data.email}&lt;/p>&lt;p>&lt;strong>Phone : &lt;/strong> ${data.data.phone}&lt;/p>`
      }, {
        'id': 0,
        'type': 'LOADER',
        'direction': 'LEFT'
      }]

      const updatedData = [...filteredChats].concat(newData);

      setChats(updatedData)
      setTimeout(() => {
        setNewLeftMessage(chatServerData[0], updatedData)
      }, 2000);

    } catch (error) {
      setLoading(false);
      if (error.status === 422) {
        setValidationErrors(error.error);
        showToast("error", "Please Check form values again.");
      } else {
        showToast("error", error);
      }
    }
  };

  const setNewLeftMessage = (message, data = []) => {
    const filteredChats = data.filter((item) => item.type !== 'LOADER' && item.type !== 'FORM')

    const children = []
    if (message.children && message.children.length) {
      for (let index = 0; index < message.children.length; index++) {
        const element = message.children[index];
        children.push({
          'id': element?._id ? element?._id : element?.id,
          'parentId': element?.parentId,
          'type': 'TEXT',
          'text': element?.question,
        })
      }
    }

    const newData = [{
      'id': message?._id ? message?._id : message?.id,
      'parentId': message?.parentId,
      'type': 'TEXT',
      'direction': 'LEFT',
      'text': message?.question,
      children: children
    }]

    const updatedData = [...filteredChats].concat(newData);
    setChats(updatedData)
  }

  const onClickChatItem = (item, parent) => {
    if (item.id === 0 || chatEnded) {
      return;
    }

    const node = findNodeByIdAndCheckChildren(chatServerData[0], item.id);
    console.log(node, item);
    if (node) {
      const chatItems = [...chats]

      const newData = [{
        'id': item.id,
        'type': 'TEXT',
        'direction': 'RIGHT',
        'text': item?.text,
      }, {
        'id': 0,
        'type': 'LOADER',
        'direction': 'LEFT'
      }]

      const updatedData = [...chatItems].concat(newData);

      setChats(updatedData)

      setTimeout(() => {
        setNewLeftMessage(node.children[0], updatedData)
      }, 2000);
    } else {
      const chatItems = [...chats]
      const newData = [{
        'id': 0,
        'type': 'TEXT',
        'direction': 'LEFT',
        'text': 'Thank you for your response we will be in touch soon with you.',
      }]

      const updatedData = [...chatItems].concat(newData);
      setChats(updatedData)
      setChatEnded(true)
      sessionStorage.clear()
    }
    saveSteps(item.text, parent.text)
    // const chatElement = document.querySelector('.chat-inner');
    // chatElement.scrollTop(chatElement?.scrollHeight)
  }

  const findNodeByIdAndCheckChildren = (node, targetId) => {
    if (node._id === targetId) {
      if (node.children && node.children.length > 0) {
        return node;
      }
    } else if (node.children && Array.isArray(node.children)) {
      for (const child of node.children) {
        const result = findNodeByIdAndCheckChildren(child, targetId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const htmlDecode = (content) => {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  const saveSteps = async (answer, question) => {

    try {
      const chatId = sessionStorage.getItem('PODDAR_CHAT_ID')
      if (chatId) {
        await saveStepsApi(chatId, { answer, question });
      }
    } catch (error) {

    }
  };

  return (
    <>
      <div className="chat-wrapper  mobile-chat-wrapper">
     
        <div >
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="card" id="poddar-chat">
                <div className="card-header  p-3 bg-info text-white border-bottom-0">
                  <p className="mb-0 fw-bold"> Chat with us now</p>
                  <div className="chat-handle-btn">
                     
                  <img
                    src={Images.minus}
                    alt="minimise button"
                    onClick={handleChatClick}
                  />
                    <img
                    src={Images.Close}
                    alt="close button"
                    onClick={() => { handlePopUp() }}
                  />
                   
                  </div>
                  
                </div>
                <div className="card-body chat-inner" >
                  {chats?.map((item, i) => (
                    <div key={`chats_${i}`}>
                      {
                        item.direction === "LEFT" && item.type === "TEXT" &&
                        <>
                          <div className={`d-flex flex-row justify-content-start bot-box-wrapper`}>
                            <img src={Images.chatbot} alt="avatar 1" />
                            <div className="p-3 ms-3 avatar-text">
                              <div className="small mb-0" dangerouslySetInnerHTML={{ __html: htmlDecode(item?.text) }}></div>
                            </div>
                          </div>
                          <div className='d-flex flex-row flex-wrap gap-2 justify-content-start option-wrapper'>
                            {
                              item.children && item.children.length > 0 &&
                              [...item.children].map((child, i) => (
                                <div onClick={() => onClickChatItem(child, item)} className="avatar-text options" key={`childrens_${i}`}>
                                  <div className="small mb-0" dangerouslySetInnerHTML={{ __html: htmlDecode(child?.text) }}></div>
                                </div>
                              ))
                            }
                          </div>
                        </>
                      }
                      {
                        item.direction === "LEFT" && item.type === "LOADER" &&
                        <div className={`d-flex flex-row justify-content-start bot-box-wrapper`}>
                          <img src={Images.chatbot} alt="avatar 1" />
                          <div className="p-3 ms-3 avatar-text">
                            {/* <div className="spinner-grow spinner-grow-sm" role="status"></div> */}
                            <div class="chat-loader">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                        </div>
                      }
                      {
                        item.direction === "RIGHT" && item.type === "TEXT" &&
                        <div className="d-flex flex-row justify-content-end  mb-4 user-box-wrapper">
                          <div className="p-3 me-3 border user-text">
                            <div className="small mb-0" dangerouslySetInnerHTML={{ __html: htmlDecode(item?.text) }}></div>
                          </div>
                          <img src={Images.user} alt="avatar 1" />
                        </div>
                      }
                      {
                        item.type === "FORM" &&
                        <div className="d-flex flex-row justify-content-start mb-4 bot-box-wrapper">
                          <img src={Images.chatbot} alt="avatar 1" />
                          <div className="p-3 ms-3 chatbot-form">
                            <Row className="form-wrap">
                              <div className="form-item">
                                <Form.Control
                                  type="text"
                                  name="name"
                                  id="name"
                                  required
                                  value={formData?.name || ""}
                                  onChange={(e) => {
                                    handleOnChange(e.target.name, e.target.value);
                                  }}
                                />
                                <label htmlFor="name">Name *</label>
                              </div>
                              {hasError(validationErrors, "name")}
                            </Row>
                            <Row className="form-wrap">
                              <div className="form-item">
                                <Form.Control
                                  type="email"
                                  name="email"
                                  id="email"
                                  required
                                  value={formData?.email || ""}
                                  onChange={(e) => {
                                    handleOnChange(e.target.name, e.target.value);
                                  }}
                                />
                                <label htmlFor="email">Email *</label>
                              </div>
                              {hasError(validationErrors, "email")}
                            </Row>
                            <Row className="form-wrap">
                              <div className="form-item">
                                <Form.Control
                                  type="number"
                                  name="phone"
                                  id="phone"
                                  required
                                  value={formData?.phone || ""}
                                  onChange={(e) => {
                                    handleOnChange(e.target.name, e.target.value);
                                  }}
                                />
                                <label htmlFor="phone">Phone No. *</label>
                              </div>
                              {hasError(validationErrors, "phone")}
                            </Row>
                            <div className="digital-card-btn">
                              <a
                                className="digital-btn animated-button"
                                onClick={() => submit()}
                              >
                                {loading && <>Please wait..</>}
                                {!loading && <>Send</>}
                              </a>
                            </div>
                          </div>
                        </div>
                      }

                    </div>
                  ))}

                  {/* <div className="d-flex flex-row justify-content-start mb-4 bot-box-wrapper">
                    <img src={Images.chatbot} alt="avatar 1" />
                    <div className="p-3 ms-3">
                      <p className="small mb-0">...</p>
                    </div>
                  </div> */}
                  <div ref={chatBottomRef} ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      {showPopup && (
        <Modal className="chat-modal" show={showPopup} onHide={handlePopUp} centered>
          <Modal.Body>Are you sure you want to close this chat? </Modal.Body>
          <Modal.Footer className="chat-modal-footer">
            <div className="chat-btn digital-card-btn">
              <Button className="digital-btn animated-button" onClick={() => { handlePopUp(); setIsChatOpen(); }}>Yes</Button>
              <Button className="digital-btn animated-button" onClick={() => { handlePopUp() }}>No</Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default Chat;
