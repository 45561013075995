import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { newsAndEventList as newsAndEventListApi } from "../../../helpers/api";
import { useEffect } from "react";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import InlineLoader from "../../comman/InlineLoader";
import config from "../../../config";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const NewsAndEvent = () => {
  const [loading, setLoading] = useState(true);
  const [newsAndEventsData, setNewsAndEventsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await newsAndEventListApi();
      setNewsAndEventsData(data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  }
  return (
    <>
      <CommonMeta
        title="Recent News and Events Updates at Poddar Pigments Ltd"
        description="Stay updated with the latest news and events at Poddar Pigments Ltd. Join us on our journey of innovation and progress."
        keywords="new & events updates, plastic masterbatch manufacturer, masterbatch company in India, color masterbatch manufacturer,"
        name="News & Event"
        type="article"
      />
      <Header />
      <div className="top-banner-image news-bg">
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                   {t("News, Events & Awards")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
          <Row>
            
              <div className="heading-text news-head-text">
                <h2> {t("Poddar Pigments Limited Shines Bright")}</h2>
              
              <p> {t("We are delighted to share the wonderful news that Poddar Pigments Limited has been consistently recognized and celebrated for its remarkable achievements and unwavering commitment to excellence. Several milestones have marked our journey, and we are thrilled to share these moments with our valued customers and partners.")}</p>
              </div>
            
          </Row>
        <InlineLoader loading={loading} />
        {!loading && (
          <Row className="news-main-wrapper">
            {newsAndEventsData.map((item, index) => (
              <div className="box-wrapper">
                <div className="news-event-box">
                  <div className="news-event-image">
                    <img
                      loading="lazy"
                      src={`${config.NEWS_AND_EVENT_IMG_URL}/${item.image}`}
                      className="img-fluid"
                      alt=""
                    />
                    <div className="card-body">
                      <div className="details">
                        <h5>{t(`${item.title}`)}</h5>
                        <p> {t(`${item.shortContent}`)}</p>
                      </div>
                      <div className="read-more-link">
                        <Link
                          to={`/news-events-and-awards/details/${encodeURI(
                            item.title 
                          )}`}   
                        >
                          {t("more")} <i className="icon icon-next"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
};
export default NewsAndEvent;
