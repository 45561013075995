import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Images from "../../helpers/images";
import Chat from "../pages/chatBot";
import ScrollToTop from "react-scroll-to-top";

const FloatingIcon = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatClick = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className="floating-icon-wrapper">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="social-icon">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="facebook"
                    href="https://www.facebook.com/profile.php?id=100076247146704"
                  >
                    <img
                      loading="lazy"
                      src={Images.facebook}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="instagram"
                    href="https://www.instagram.com/poddarpigmentsltd/"
                  >
                    <img
                      loading="lazy"
                      src={Images.instagram}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="linkedin"
                    href=" https://www.linkedin.com/company/poddar-pigments-limited/mycompany/?viewAsMember=true"
                  >
                    <img
                      loading="lazy"
                      src={Images.linkdin}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="youtube"
                    href="https://www.youtube.com/@PoddarPigmentsLimited"
                  >
                    <img
                      loading="lazy"
                      src={Images.you_Tube}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a className="chat" href={void 0} onClick={handleChatClick}>
                    <img
                      loading="lazy"
                      src={Images.chat}
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="mobile-chat-box scroll-top">
              <a className="chat" href={void 0} onClick={handleChatClick}>
                <img
                  loading="lazy"
                  src={Images.chat}
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            {isChatOpen && <Chat setIsChatOpen={handleChatClick} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FloatingIcon;
