import React from "react";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import CommonMeta from "../../comman/CommonMeta";

const Privacy = () => {
  return (
    <>
     <CommonMeta
        title=""
        description=""
        keywords=""
        name=""
        type=""
      />
      <Header />
      
      <Footer />
    </>
  );
};
export default Privacy;
