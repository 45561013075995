import React from 'react';

const InlineLoader = ({loading}) => {
    if(!loading) {
        return (
            <>
            </>
        )

    }
    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="spinner-border theme-color-spinner" role="status"></div>
                <span className="sr-only mt-3">Please Wait</span>
            </div>
        </>
    );
}
export default InlineLoader;