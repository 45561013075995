import { Col, Container, Form, Row } from "react-bootstrap";
import Footer from "../comman/footer";
import Header from "../comman/header";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { hasError, showToast } from "../../helpers/utils";
import { contactUs as contactUsApi } from "../../helpers/api";
import { isMobile } from "react-device-detect";
import CommonMeta from "../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const title = queryParams.get("title");

  const [validationErrors, setValidationErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleOnChange = (name, value) => {
    let errors = validationErrors.filter((err) => err.key !== name);
    setValidationErrors(errors);
    setFormData((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      await contactUsApi(formData);
      showToast("success", "Your query has been submitted successfully.");
      setLoading(false);
      setFormData({});
      setValidationErrors([]);
    } catch (error) {
      setLoading(false);
      if (error.status === 422) {
        setValidationErrors(error.error);
        showToast("error", "Please Check form values again.");
      } else {
        showToast("error", error);
      }
    }
  };
  
  // for lang change
  const { t } = useTranslation();
  
  return (
    <>
      <CommonMeta
        title="Contact us: Poddar Pigments Ltd"
        description="Poddar Pigments: Masterbatch Manufacturer & Supplier in India, Contact: +91-141-2770202, Email Id: jaipur@poddarpigmentsltd.com"
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Contact us"
        type="article"
      />
      <Header />
      <div className="top-banner-image contact-us-bg">
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("Contact Us")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="news-events-wrapper contact">
        <Row>
          <Col lg={6}>
            <div className="contact-us-address">
              <div className="heading-text">
                <h2> {t("Corporate Office")}</h2>
              </div>
              <div className="address-box">
                <h3>CHENNAI</h3>
                <p>
                  Rosy Tower, 3rd Floor 8, Mahatma Gandhi Road, Nungambakkam,
                  Chennai 600034
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:+91-44-2826 0929">(+91) 44 2826 0929</a>
                  {/* <a href="tel:+91-4500 8003">4500 8003</a> */}
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:headoffice@poddarpigmentsltd.com">
                    headoffice@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
              <div className="address-box">
                <div className="heading-text">
                  <h2> {t("Registered Office and Works")}</h2>
                </div>
                <h3>JAIPUR WORKS</h3>
                <p>
                  E-10-11 & F-14 to 16, RIICO Industrial Area, Sitapura, Jaipur-
                  302022, Rajasthan, India
                </p>
                <p>
                  <i className="icon-phone"></i> :
                  <a href="tel:+91-141-2770202">+91-141-2770202</a>
                  <a href="tel:+91-141-2770202">, +91-141-2770203</a>
                  <a href="tel:+91-141-2770287">,+91-141-2770287,</a>
                  <a href="tel:+91-141-2770291">+91-141-2770291</a>
                </p>
                <p>
                  <i className="icon-email"></i> :
                  <a href="mailto:jaipur@poddarpigmentsltd.com">
                    jaipur@poddarpigmentsltd.com
                  </a>
                </p>
              </div>
              <div className="address-box">
                <div className="heading-text">
                  <h2> {t("Manufacturing Location: 2")}</h2>
                </div>
                <h3>Chaksu, Jaipur</h3>
                <p>
                  Greater Sitapura Industrial Park, National Highway 12,
                  (Jaipur-Tonk Road) Village - Brijpura (Chaksu) Jaipur - 303901
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="career-right-box">
              <h3> {t("Get in Touch with Poddar Pigments")} </h3>
              <div className="career-left-box ">
                <p> {t("Contact us for masterbatch solutions, partnership inquiries, or general information. We're here to assist you.")}
                  
                </p>
              </div>
              <Form>
                <Row className="form-wrap">
                  <div className="form-item">
                    <Form.Control
                      type="text"
                      name="name"
                      id="name"
                      required
                      value={formData?.name || ""}
                      onChange={(e) => {
                        handleOnChange(e.target.name, e.target.value);
                      }}
                    />
                    <label htmlFor="name"> {t("Name *")}</label>
                  </div>
                  {hasError(validationErrors, "name")}
                </Row>
                <Row className="form-wrap">
                  <div className="form-item">
                    <Form.Control
                      type="email"
                      name="email"
                      id="email"
                      required
                      value={formData?.email || ""}
                      onChange={(e) => {
                        handleOnChange(e.target.name, e.target.value);
                      }}
                    />
                    <label htmlFor="email"> {t("Email *")}</label>
                  </div>
                  {hasError(validationErrors, "email")}
                </Row>
                <Row className="form-wrap">
                  <div className="form-item">
                    <Form.Control
                      type="number"
                      name="phone"
                      id="phone"
                      required
                      value={formData?.phone || ""}
                      onChange={(e) => {
                        handleOnChange(e.target.name, e.target.value);
                      }}
                    />
                    <label htmlFor="phone"> {t("Phone No. *")}</label>
                  </div>
                  {hasError(validationErrors, "phone")}
                </Row>
                <Row className="form-wrap">
                  <div className="form-item">
                    <Form.Control
                      type="number"
                      name="mobile"
                      id="mobile"
                      required
                      value={formData?.mobile || ""}
                      onChange={(e) => {
                        handleOnChange(e.target.name, e.target.value);
                      }}
                    />
                    <label htmlFor="mobile"> {t("Mobile No. *")}</label>
                  </div>
                  {hasError(validationErrors, "mobile")}
                </Row>
                <Row className="form-wrap">
                  <div className="form-item">
                    <Form.Control
                      type="text"
                      name="companyName"
                      id="companyName"
                      required
                      value={formData?.companyName || ""}
                      onChange={(e) => {
                        handleOnChange(e.target.name, e.target.value);
                      }}
                    />
                    <label htmlFor="companyName"> {t("Company Name *")}</label>
                  </div>
                  {hasError(validationErrors, "companyName")}
                </Row>
                <Row className="form-wrap">
                  <div className="form-item">
                    <Form.Control
                      type="text"
                      name="address"
                      id="address"
                      required
                      value={formData?.address || ""}
                      onChange={(e) => {
                        handleOnChange(e.target.name, e.target.value);
                      }}
                    />
                    <label htmlFor="address"> {t("Address *")}</label>
                  </div>
                  {hasError(validationErrors, "address")}
                </Row>
                <Row className="form-wrap">
                  <Col>
                    <div className="form-item">
                      <Form.Control
                        as="textarea"
                        name="message"
                        id="message"
                        required
                        value={formData?.message || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="message"> {t("Leave a message here *")}</label>
                    </div>
                    {hasError(validationErrors, "message")}
                  </Col>
                </Row>

                <div className="digital-card-btn">
                  <a
                    className="digital-btn animated-button"
                    onClick={() => submit()}
                  >
                    {loading && <>Please wait..</>}
                    {!loading && <> {t("Send Message")}</>}
                  </a>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default ContactUs;
