import React, { createContext, useEffect, useState } from "react";
// Context has been created
const ThemeContext = createContext(false);
// Provider
const ThemeProvider = ({ children }) => {
    const [toggle, setToggle] = useState('light');
    const toggleFunction = () => {
        const type = toggle === 'dark' ? 'light' : 'dark';
        localStorage.setItem('theme', type)
        if (type === 'dark') {
            if (document.body) document.body.classList.remove('light')
            if (document.body) document.body.classList.add('dark')
        } else {
            if (document.body) document.body.classList.remove('dark');
            if (document.body) document.body.classList.add('light');
        }
        setToggle(type);
    };

    useEffect(() => {
        const theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : (checkDayLight() ? 'dark' : 'light');
        if (theme === 'dark') {
            if (document.body) document.body.classList.add('dark')
            if (document.body) document.body.classList.remove('light')
        } else {
            if (document.body) document.body.classList.remove('dark');
            if (document.body) document.body.classList.add('light');
        }
        setToggle(theme)
    }, [])

    const checkDayLight = () => {
        const currentDate = new Date()

        const startDate = new Date(currentDate.getTime());
        startDate.setHours('18');
        startDate.setMinutes('00');
        startDate.setSeconds('00');

        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 1);

        const endDate = new Date(nextDay.getTime());
        endDate.setHours('06');
        endDate.setMinutes('00');
        endDate.setSeconds('00');
        return startDate < currentDate && endDate > currentDate
    }


    return (
        <ThemeContext.Provider value={{ toggle, toggleFunction }}>
            {children}
        </ThemeContext.Provider>
    );
};
export { ThemeContext, ThemeProvider };