import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Images from "../../../helpers/images"
import { useTranslation } from "react-i18next";

const Sustainability = () => {
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
 
  return (
    <div className="sustainability-section" id="Sustainability">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="heading-text">
              <h4>{t("SustainabilitySec.title")}</h4>
              <p>{t("SustainabilitySec.desc")}</p>
            </div>
          </Col>
        </Row>
        <Row >
          <Col lg={12}>
            <div className="sustainability-box-wrapper">
              <div className="sustainability-box-left">
                <div className="sustainability-box-inner">
                  <Link to="/sustainability/process">
                    <div className="image-overlay"></div>
                    <img loading="lazy" src={Images.Process} className='img-fluid' alt='' />
                    <div className="sustainability-text">
                      <div className="sustainability-text-inner">
                        <h5>{t("SustainabilitySec.imgtxt1")}</h5>
                        <div className="read-more-link"><Link to="/sustainability/process">{t("SustainabilitySec.more")} {'>'}</Link></div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="sustainability-box-inner">
                  <Link  to="/sustainability/product">
                    <div className="image-overlay"></div>
                    <img loading="lazy" src={Images.Product} className='img-fluid' alt='' />
                    <div className="sustainability-text">
                      <div className="sustainability-text-inner">
                        <h5>{t("SustainabilitySec.imgtxt3")}</h5>
                        <div className="read-more-link"><Link to="/sustainability/product">{t("SustainabilitySec.more")} {'>'}</Link></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="sustainability-box-right">
                <div className="sustainability-box-inner">
                  <Link to="/sustainability/people">
                    <div className="image-overlay"></div>
                   
                   <img src={window.innerWidth > 991 ? Images.People : Images.people_m } className='img-fluid' alt="power energy" />
                    <div className="sustainability-text">
                      <div className="sustainability-text-inner">
                        <h5>{t("SustainabilitySec.imgtxt2")}</h5>
                        <div className="read-more-link"><Link to="/sustainability/people">{t("SustainabilitySec.more")} {'>'}</Link></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  )
}
export default Sustainability