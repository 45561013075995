import { Col, Container, Form, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import { useContext, useState } from "react";
import { createYourOwn as createYourOwnApi } from "../../../helpers/api";
import { hasError, showToast } from "../../../helpers/utils";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext";
import { t } from "i18next";

const CreateYourOwn = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const [validationErrors, setValidationErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleOnChange = (name, value) => {
    let errors = validationErrors.filter((err) => err.key !== name);
    setValidationErrors(errors);
    setFormData((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      console.log(JSON.stringify(formData));
      await createYourOwnApi(formData);
      showToast("success", "Your query has been submitted successfully.");
      setLoading(false);
      setFormData({});
      setValidationErrors([]);
    } catch (error) {
      setLoading(false);
      if (error.status === 422) {
        setValidationErrors(error.error);
        showToast("error", "Please Check form values again.");
      } else {
        showToast("error", error);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                     {t("Create Your Own Color")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="create-own-wrapper">
        <Container>
          <Row>
            <Col>
              <h1> {t("Create Your Own Color")}</h1>
            </Col>
          </Row>
          <Row>
            <div id="swatch">
              <input
                type="color"
                name="pantoneColor"
                value={formData?.pantoneColor || ""}
                onChange={(e) => {
                  handleOnChange(e.target.name, e.target.value);
                }}
              />
              <div className="info">
                <h1> {t("Pick Your Color")}</h1>
              </div>
              {hasError(validationErrors, "pantoneColor")}
            </div>
          </Row>
          <Row>
            <Form>
              <Row>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        id="companyName"
                        required
                        name="companyName"
                        value={formData?.companyName || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="companyName"> {t("Company Name *")}</label>
                    </div>
                    {hasError(validationErrors, "companyName")}
                  </div>
                </Col>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="name"
                        id="Name"
                        required
                        value={formData?.name || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="Name"> {t("Name *")}</label>
                    </div>
                    {hasError(validationErrors, "name")}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        type="email"
                        name="email"
                        id="Email"
                        required
                        value={formData?.email || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="Emai"> {t("Email*")}</label>
                    </div>
                    {hasError(validationErrors, "email")}
                  </div>
                </Col>

                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        type="number"
                        name="phone"
                        id="phone"
                        required
                        value={formData?.phone || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="phone">  {t("Mobile No.*")}</label>
                    </div>
                    {hasError(validationErrors, "phone")}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        as="textarea"
                        name="application"
                        id="application"
                        required
                        value={formData?.application || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="application">
                         {t("Leave a Application here*")}
                      </label>
                    </div>
                    {hasError(validationErrors, "application")}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        type="text"
                        name="polymer"
                        id="polymer"
                        required
                        value={formData?.polymer || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="polymer"> {t("Polymer*")}</label>
                    </div>
                    {hasError(validationErrors, "polymer")}
                  </div>
                </Col>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <div class="wrap">
                        <div class="select">
                          <select
                            class="select-text"
                            placeholder="sxdrtfcgvjbh"
                            type="text"
                            name="foodApproval"
                            id="foodApproval"
                            required
                            value={formData?.foodApproval || ""}
                            onChange={(e) => {
                              handleOnChange(e.target.name, e.target.value);
                            }}
                            defaultValue="Food Approval"
                          >
                            <option value="" disabled selected></option>
                            <option value={"Yes"}>{t("Yes")}</option>
                            <option value={"No"}> {t("No")}</option>
                          </select>
                          <label class="select-label" htmlFor="foodApproval">
                             {t("Food Approval*")}
                          </label>
                        </div>
                      </div>

                      {/* <Form.Select
                      
                        type="text"
                        name="foodApproval"
                        id="foodApproval"
                        required
                        value={formData?.foodApproval || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                        defaultValue="Food Approval"
                      >
                        <option>Food Approval</option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                      </Form.Select> */}
                      {/* <label htmlFor="foodApproval">Food Approval*</label> */}
                    </div>
                    {hasError(validationErrors, "foodApproval")}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        type="text"
                        name="heatStability"
                        id="heatStability"
                        required
                        value={formData?.heatStability || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="heatStability"> {t("Heat Resistance*")}</label>
                    </div>
                    {hasError(validationErrors, "heatStability")}
                  </div>
                </Col>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        type="text"
                        name="lightFastness"
                        id="lightFastness"
                        required
                        value={formData?.lightFastness || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="lightFastness"> {t("Light Fastness*")}</label>
                    </div>
                    {hasError(validationErrors, "lightFastness")}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-wrapp">
                    <div className="form-item">
                      <Form.Control
                        as="textarea"
                        name="comment"
                        id="comment"
                        required
                        value={formData?.comment || ""}
                        onChange={(e) => {
                          handleOnChange(e.target.name, e.target.value);
                        }}
                      />
                      <label htmlFor="comment"> {t("Leave a comment here*")}</label>
                    </div>
                    {hasError(validationErrors, "comment")}
                  </div>
                </Col>
              </Row>
              <div className="digital-card-btn">
                <span
                  className="digital-btn animated-button"
                  onClick={() => submit()}
                >
                  {loading && <>Please wait..</>}
                  {!loading && <>Send Message</>}
                </span>
              </div>
            </Form>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default CreateYourOwn;
