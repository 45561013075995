import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function CommonMeta({ title, description, name, type, keywords }) {
  return (
    <Helmet>
      <title>Poddar Pigments: Masterbatch Manufacturer & Supplier in India | {title}</title>

      <meta name='description' content={description} />

      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={name} />
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />

      <meta itemProp="name" content={title} />
      <meta name="author" content={name} />
      <meta itemProp="description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}