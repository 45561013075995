import { Col, Container, Row } from "react-bootstrap";
import Images from "../../helpers/images";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { ThemeContext } from "../../context/themeContext";
import FloatingIcon from "./floatingIcon";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { toggle } = useContext(ThemeContext);

  const [visible, setVisible] = useState(false);
  // for lang change
  const { t } = useTranslation();

  const d = new Date();
  const year = d.getFullYear();

  return (
    <>
      <div className="footer-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <ul className="footer-nav">
                  <li className="footer-nav-grid">
                    <ul className="footer-nav-list">
                      <li className="footer-nav-link-head">
                        {t("footer.About.title")}
                      </li>
                      <li className="footer-links">
                        <Link to="/the-company">{t("footer.About.menu1")}</Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/board-members">
                          {t("footer.About.menu2")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/csr-initiatives">
                          {t("footer.About.menu3")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/locations">{t("footer.About.menu4")}</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="footer-nav-grid">
                    <ul className="footer-nav-list">
                      <li className="footer-nav-link-head">
                        {t("footer.Products.title")}
                      </li>
                      <li className="footer-links">
                        <Link to="/masterbatch-for-man-made-fiber">
                          {t("footer.Products.menu1")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/masterbatches-for-plastic-applications">
                          {t("footer.Products.menu2")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/functional-and-additive-masterbatches">
                          {t("footer.Products.menu3")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="footer-nav-grid">
                    <ul className="footer-nav-list">
                      <li className="footer-nav-link-head">
                        {t("footer.Services.title")}
                      </li>
                      <li className="footer-links">
                        <Link to="/r-and-d-support">
                          {t("footer.Services.menu1")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/technical-services-support">
                          {t("footer.Services.menu2")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/marketing-support">
                          {t("footer.Services.menu3")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/experience-center">
                          {t("footer.Services.menu4")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/digital-shade-card">
                          {t("footer.Services.menu5")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="footer-nav-grid">
                    <ul className="footer-nav-list">
                      <li className="footer-nav-link-head">
                        {t("footer.Sustainability.title")}
                      </li>
                      <li className="footer-links">
                        <Link to="/sustainability/process">
                          {t("footer.Sustainability.menu1")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/sustainability/product">
                          {t("footer.Sustainability.menu2")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/sustainability/people">
                          {t("footer.Sustainability.menu3")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="footer-nav-grid">
                    <ul className="footer-nav-list">
                      <li className="footer-nav-link-head">
                        {t("footer.InvestorRelations.title")}
                      </li>
                      <li className="footer-links">
                        <Link to="/investor-relations">
                          {t("footer.InvestorRelations.menu1")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/investor-relations">
                          {t("footer.InvestorRelations.menu2")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/investor-relations">
                          {t("footer.InvestorRelations.menu3")}
                        </Link>
                      </li>
                      <div>
                        <div style={{ display: visible ? "block" : "none" }}>
                          <div>
                            <li className="footer-links">
                              <Link to="/investor-relations">
                                {t("footer.InvestorRelations.menu4")}
                              </Link>
                            </li>
                            <li className="footer-links">
                              <Link to="/investor-relations">
                                {t("footer.InvestorRelations.menu5")}
                              </Link>
                            </li>
                            <li className="footer-links">
                              <Link to="/investor-relations">
                                {t("footer.InvestorRelations.menu6")}
                              </Link>
                            </li>
                          </div>
                        </div>
                        <li
                          className="show-hide-btn"
                          onClick={() => setVisible(!visible)}
                        >
                          {visible
                            ? t("footer.InvestorRelations.less")
                            : t("footer.InvestorRelations.more")}
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li className="footer-nav-grid">
                    <ul className="footer-nav-list">
                      <li className="footer-nav-link-head">{t("footer.OtherLinks.title")}</li>
                      <li className="footer-links">
                        <Link to="/career">{t("footer.OtherLinks.menu1")}</Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/news-events-and-awards">
                          {t("footer.OtherLinks.menu2")}
                        </Link>
                      </li>
                      <li className="footer-links">
                        <Link to="/contact-us">{t("footer.OtherLinks.menu3")}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="mob-view" lg={12}>
              <div className="border-bottom-section"></div>
            </Col>
          </Row>
          <div className="footer-bottom">
            <Row className="align-items-center">
              <Col lg={3}>
                <div className="footer-logo-section">
                  <div className="footer-logo">
                    {toggle === "light" ? (
                      <Link to="/">
                        <img
                          loading="lazy"
                          src={Images.FLogo}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    ) : (
                      <Link to="/">
                        <img
                          loading="lazy"
                          src={Images.FLogoDark}
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    )}
                  </div>
                  {/* <div className="border-section"></div> */}
                  {/* <div className="text">Experience The Poddar <strong>Advantage<span className="icon">Ⓡ </span></strong></div> */}
                </div>
              </Col>
              <Col lg={7}>
                <div className="copyright-text">
                  <p>
                    Copyright @{year} Poddar Pigments Ltd. All rights reserved
                  </p>
                  {/* <Link to="/privacy-policy">Privacy Policy</Link>
                  <Link to="/terms-of-use">Terms of Use</Link> */}
                </div>
              </Col>
              <Col lg={2}>
                <div className="social-icon">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/company/poddar-pigments-limited/mycompany/?viewAsMember=true"
                      >
                        <img
                          loading="lazy"
                          src={Images.linkedIn}
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/profile.php?id=100076247146704"
                      >
                        <img
                          loading="lazy"
                          src={Images.fb}
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/poddarpigmentsltd/"
                      >
                        <img
                          loading="lazy"
                          src={Images.insta}
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.youtube.com/@PoddarPigmentsLimited"
                      >
                        <img
                          loading="lazy"
                          src={Images.youTube}
                          className="img-fluid"
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <FloatingIcon />
    </>
  );
};

export default Footer;
