import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import {useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  privateDigitalShadeCard as privateDigitalShadeCardApi,
  unlockPrivateDigitalShadeCard as unlockPrivateDigitalShadeCardApi,
  getPrivateDigitalShadeCardImage as getPrivateDigitalShadeCardImageApi
} from "../../../helpers/api";
import PrivateBook from "./privateBook"
import InlineLoader from "../../comman/InlineLoader";
import { showToast } from "../../../helpers/utils";
import { Link } from "react-router-dom";

const PrivateDigitalShadeCard = () => {

  const params = useParams();
  const [locked, setLocked] = useState(true)
  const [expired, setExpired] = useState(false)
  const [images, setImages] = useState([])
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(true)
  const [digitalShadeCard, setDigitalShadeCard] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await privateDigitalShadeCardApi(params.id)
      setDigitalShadeCard(data.result);
      const expiry = new Date(data.result.expiry);
      const now = new Date();
      if (expiry < now) {
        setExpired(true)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  const unLock = async () => {
    if (password == "") {
      showToast('error', 'Please enter password.');
      return;
    }

    try {
      const { data } = await unlockPrivateDigitalShadeCardApi(params.id, { password: password })
      if (data.status) {
        setLocked(false);
      } else {
        showToast('error', data.message);
      }
      setLoading(false)
    } catch (error) {
      showToast('error', 'Please enter password.');
      setLoading(false)
    }
  };

  useEffect(() => {
    getImages()
  }, [locked])

  const getImages = async () => {
    for (let i = 0; i < digitalShadeCard?.images?.length; i++) {
      const element = digitalShadeCard?.images[i];

      const { data } = await getPrivateDigitalShadeCardImageApi(element.id, { password: password })

      const base64Image = arrayBufferToBase64(data);
      setImages((prev) =>
        [...prev, `data:image/webp;base64,${base64Image}`])

    }
  }

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  return (
    <>
      <Header />
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb investorRelations">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item " aria-current="page"><Link to="/digital-shade-card">Digital Shade Card</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Reliance</li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-desc-section private-digital-shade-card-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">
                <h2>Digital Shade Card</h2>
              </div>
            </Col>
          </Row>
          <InlineLoader loading={loading} />
          {
            expired &&
            <Row className="justify-content-center private-digital-shade-card">
              <Col xl={5}>
                <Card>
                  <Card.Body className="p-0">
                    <Row className="g-0">
                      <Col lg={12} className="p-4">
                        <h6 className="h5 mb-0 mt-3 text-center"><strong>Welcome {digitalShadeCard.companyName}</strong></h6>
                        <p className="text-muted mt-1 mb-4 text-center">
                          The page you are looking for is already expired
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          }
          {
            !expired && locked &&
            <Row className="justify-content-center private-digital-shade-card">
              <Col xl={5}>
                <Card>
                  <Card.Body className="p-0">
                    <Row className="g-0">
                      <Col lg={12} className="p-4">
                        <h6 className="h5 mb-0 mt-3 text-center"><strong>Welcome {digitalShadeCard.companyName}</strong></h6>
                        <p className="text-muted mt-1 mb-4 text-center">
                          This page is password protected please enter password which you have received.
                        </p>
                        <div className="form-item mt-3">
                        <Form.Control
                        type="text"
                        name="Password"
                        id="Password"
                        required
                        value={password || ""}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                      />
                      
                          <label htmlFor="Password">Password *</label>
                        </div>

                        <div className="digital-card-btn mt-3">
                          <a className="digital-btn animated-button" onClick={() => unLock()} > Unlock </a>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          }

          {
            !expired && !locked && !loading &&
            <div className="table-responsive digital-main-wrapper">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="title-wrapper">
                        <h6>{digitalShadeCard.title}</h6>
                      </div>
                    </td>
                    <td>
                      <div className="digital-card-btn digital-card-btn-wrapper">
                        <a
                          onClick={() => setShow(true)}
                          className="digital-btn animated-button"
                        >
                          View <i className="icon-view"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
        </Container>
      </div>
      <Footer />
      {
        !expired && !locked &&
        <Modal className="book-modal" size="xl" show={show} onHide={handleClose} >
          <Modal.Body className="digital-modal">
            <PrivateBook selectedDigitalShade={digitalShadeCard} images={images} handleClose={handleClose} />
          </Modal.Body>
        </Modal>
      }
    </>
  );
};
export default PrivateDigitalShadeCard;
