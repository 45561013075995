import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import { useEffect, useState } from "react";
import { experienceCenterList as experienceCenterListApi } from "./../../../helpers/api";
import config from "../../../config";
import Images from "../../../helpers/images";
import InlineLoader from "../../comman/InlineLoader";
import { useCallback } from "react";
import { debounce } from "lodash";
import CustomPagination from "../../comman/CustomPagination";
import { isMobile } from "react-device-detect";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const ExperienceCenter = () => {
  const filterData = {
    limit: 10,
    page: 1,
    ci: "",
    productName: "",
    productCode: "",
    category: "",
    contentOnColorant: "",
    lightFastness: "",
    heatStability: "",
    washingN: "",
    washingPES: "",
    washingPA: "",
    washingCO: "",
    sublimationN: "",
    sublimationPES: "",
    sublimationCO: "",
  };
  const [loading, setLoading] = useState(true);
  const [experienceCenterData, setExperienceCenterData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [filters, setFilters] = useState(filterData);

  const onChangeHandle = (name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      page: 1,
      [name]: value,
    }));
  };
  const clearFilters = () => {
    setFilters(filterData);
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = async () => {
    try {
      const filter = { ...filters };
      Object.keys(filter).forEach((key) => {
        if (filter[key] === null || filter[key] === "") {
          delete filter[key];
        }
      });
      const { data } = await experienceCenterListApi(filter);
      setExperienceCenterData(data.results);
      setPagination({
        pageCount: data.totalPages,
        state: {
          pageIndex: data.page,
          pageSize: data.limit,
          totalResults: data.totalResults,
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handler = useCallback(debounce(onChangeHandle), []);

  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setSelectedItem(item);
  };
  
  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <CommonMeta
        title="Pigment Concentrates Experience Center: PES, PP, PA"
        description="Discover our Single Pigment Concentrates at the Experience Center - Poddar Pigments Ltd. Tailored for PES, PP, and PA applications."
        keywords="plastic masterbatch manufacturer, masterbatch company in Jaipur, color masterbatch manufacturer"
        name="Experience Center"
        type="article"
      />
      <Header />
      <div className="top-banner-image experience-center-services-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1> {t("Experience Center")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/r-and-d-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("R & D Support")}</h4>
                      ) : (
                        <>
                          <h4>{t("R & D Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/technical-services-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Technical Support")}</h4>
                      ) : (
                        <>
                          <h4> {t("Technical Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/marketing-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Marketing Support")}</h4>
                      ) : (
                        <>
                          <h4> {t("Marketing Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/digital-shade-card">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Digital Shade Card")}</h4>
                      ) : (
                        <>
                          <h4>{t("Digital Shade Card")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {t("Experience Center")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-desc-section">
        <Container>
          <Row className=" desc-box-wrap mb-5 mobile-order">
            <Col lg={6}>
              <p>
              {t("We welcome to an innovative Experience Center where you can explore our comprehensive range of Single Pigment Concentrates for PES, PP, and PA. With just a few clicks, you can readily access detailed product properties and effortlessly preview the captivating shades. What makes our Experience Center truly unique is the opportunity to dive into a virtual pool of colors, allowing you to craft your very own hue. Your customized shade can then be shared with us for further development, ensuring that our products perfectly match your unique requirements. Explore, create, and collaborate with us in our interactive Experience Center for a seamless and tailored pigment concentrate experience.")}

              </p>
            </Col>
            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.experienceCenter1}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.experienceCenter2}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>

        <InlineLoader loading={loading} />
        {!loading && (
          <Container className="create-own-btn">
            <div className="entries digital-card-btn">
              <strong>{t("Show")}</strong>
              <Form.Select
                value={filters.limit}
                aria-label="Default  select example"
                onChange={(e) => onChangeHandle("limit", e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
              </Form.Select>
              <strong> {t("Entries")}</strong>
              <div className="digital-card-btn ">
                <div
                  className="digital-btn animated-button"
                  onClick={() => clearFilters()}
                >
                   {t("Clear Filter")}
                </div>
              </div>
            </div>
            <div className="right-side-btn">
              <div className="digital-card-btn ">
                <Link to="/create-your-own">
                  <div className="digital-btn animated-button">
                     {t("Create Your Own Color")}
                  </div>
                </Link>
              </div>
            </div>
          </Container>
        )}
        {!loading && (
          <Container>
            <div className="table-responsive experience-table">
              <Table striped bordered>
                <thead>
                  <tr className="table-header">
                    <th rowSpan="2"></th>
                    <th rowSpan="2" className="exp-table-td-width">
                        {t("C.I.")}
                    </th>
                    <th rowSpan="2" className="exp-table-th-width">
                       {t("Product")}
                    </th>
                    <th rowSpan="2" className="exp-table-th-width">
                      Product Code {t("Product")}
                    </th>
                    <th rowSpan="2"> {t("Category")}</th>
                    <th rowSpan="2"> {t("Content on Colorant")}</th>
                    <th rowSpan="2"> {t("Light Fastness")}</th>
                    <th rowSpan="2"> {t("Heat Stability")}</th>
                    <th colSpan="4" className="exp-table-th-width">
                       {t("Washing")}
                    </th>
                    <th colSpan="3"> {t("Sublimation")}</th>
                    <th rowSpan="2"></th>
                  </tr>
                  <tr className="table-sub-head">
                    <td> {t("N")}</td>
                    <td> {t("PES")}</td>
                    <td> {t("PA")}</td>
                    <td> {t("CO")}</td>
                    <td> {t("CO")}</td>
                    <td> {t("PES")}</td>
                    <td> {t("CO")}</td>
                  </tr>
                  <tr className="exp-table-filter">
                    <td></td>
                    <td>
                      <Form.Control
                        type="text"
                        name="ci"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.ci || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="productName"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.productName || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="productCode"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.productCode || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="category"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.category || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="contentOnColorant"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.contentOnColorant || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="lightFastness"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.lightFastness || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="heatStability"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.heatStability || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="washingN"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.washingN || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="washingPES"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.washingPES || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="washingPA"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.washingPA || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="washingCO"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.washingCO || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="sublimationN"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.sublimationN || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="sublimationPES"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.sublimationPES || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="sublimationCO"
                        onChange={(e) => handler(e.target.name, e.target.value)}
                        value={filters.sublimationCO || ""}
                        placeholder="search..."
                      />
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {experienceCenterData?.map((item, i) => (
                    <tr key={`experienceCenterData${i}`}>
                      <td>
                        <div className="exp-table-img">
                          <img
                            loading="lazy"
                            src={`${config.EXPERIENCE_CENTER_IMG_URL}/${item.image}`}
                            className="img-fluid img1 rounded-3"
                            alt="Your Image"
                          />
                        </div>
                      </td>
                      <td>{item.ci.toUpperCase()}</td>
                      <td>{item.productName.toUpperCase()}</td>
                      <td>{item.productCode.toUpperCase()}</td>
                      <td>{item.category}</td>
                      <td>{item.contentOnColorant || "-"}</td>
                      <td>{item.lightFastness || "-"}</td>
                      <td>{item.heatStability || "-"}</td>
                      <td>{item.washingN || "-"}</td>
                      <td>{item.washingPES || "-"}</td>
                      <td>{item.washingPA || "-"}</td>
                      <td>{item.washingCO || "-"}</td>
                      <td>{item.sublimationCO || "-"}</td>
                      <td>{item.sublimationPES || "-"}</td>
                      <td>{item.sublimationCO || "-"}</td>
                      <td>
                        <div className="digital-card-btn">
                          <span
                            onClick={() => handleShow(item)}
                            className="digital-btn animated-button"
                          >
                            View
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {experienceCenterData.length > 0 && (
              <div className="custom-pagination">
                <CustomPagination
                  tableProps={pagination}
                  handleChangePage={(num) => handler("page", num)}
                />
              </div>
            )}
          </Container>
        )}
        {!loading && (
          <Container>
            <div className="experience-bottom-text">
              <strong> {t("Notes")}</strong>
              <ul>
                <li> {t("Heat Resistance : The highest temperature in which a pigmented sample withstands a dwell time of 5 min in the barrel of an injection-molding machine without significant color change -according to DIN EN 12877.")}
                </li>
                <li> {t("Light Fastness : Measured according to ISO 105-B02 Blue wool scale 1-8 , where 1=Very poor , 8=Excellent.")}
                </li>
                <li> {t("CONEG Regulation : Yes means that the product fulfills the requirements set in CONEG and other regulations regarding packaging & packaging waste. ( Directive EC 94/62 and amendments)")}
                </li>
                <li> {t("Food approval : Yes means that the product can be used in food contact applications according to one or more national and /or European regulations . Detailed declaration available upon request.")}
                </li>
                <li>{t("Polymer : Material used for manufacturing end article.")} </li>
              </ul>
              <strong> {t("Disclaimer")}</strong>
              <p> {t("The above information is based on our knowledge and experience and is given in good faith to be used as recommendations for trials only. As the exact conditions of processing and usage of the finished products are beyond our control, this information is provided without any engagement or warranty from our side. Buyer should verify the suitability of the product and determine the appropriate addition rate under the actual conditions of application.")} 
              </p>
            </div>
          </Container>
        )}
        {selectedItem && (
          <Modal
            className="experience-modal-wrapper"
            size="lg"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Body className="experience-modal">
              <div className="experience-modal">
                <h2>Color Selector Record</h2>
                <div className="table-responsive exp-modal-table">
                  <table>
                    <tr className="odd">
                      <td> CI :</td>
                      <td>
                        {selectedItem?.ci && selectedItem.ci.toUpperCase()}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td> Product :</td>
                      <td>
                        {selectedItem?.productName &&
                          selectedItem.productName.toUpperCase()}
                      </td>
                    </tr>
                    <tr className="odd">
                      <td> Product Code :</td>
                      <td>
                        {selectedItem?.productCode &&
                          selectedItem.productCode.toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <td> Category :</td>
                      <td>{selectedItem?.category} </td>
                    </tr>
                    <tr className="odd">
                      <td> Content on Colorant :</td>
                      <td>{selectedItem?.contentOnColorant || "-"}</td>
                    </tr>
                    <tr>
                      <td>Light Fastness : </td>
                      <td> {selectedItem?.lightFastness || "-"}</td>
                    </tr>
                    <tr className="odd">
                      <td>Heat Stability : </td>
                      <td>{selectedItem.heatStability || "-"} </td>
                    </tr>
                    <tr>
                      <td className="table-inner-data">
                        Washing :<p>N</p>
                        <p>PES</p>
                        <p>PA</p>
                        <p>CO</p>
                      </td>
                      <td className="table-inner-data">
                        &nbsp;
                        <p>{selectedItem?.washingN || "-"}</p>
                        <p>{selectedItem?.washingPES || "-"}</p>
                        <p>{selectedItem.washingPA || "-"}</p>
                        <p>{selectedItem?.washingCO || "-"}</p>
                      </td>
                    </tr>
                    <tr className="odd">
                      <td className="table-inner-data">
                        Sublimation :<p>CO </p>
                        <p>PES</p>
                        <p>CO</p>
                      </td>
                      <td className="table-inner-data">
                        &nbsp;
                        <p>{selectedItem?.sublimationCO || "-"}</p>
                        <p>{selectedItem?.sublimationPES || "-"}</p>
                        <p>{selectedItem?.sublimationCO || "-"}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>image : </td>
                      <td>
                        <div className="colored-box-mg">
                          <img
                            loading="lazy"
                            src={`${config.EXPERIENCE_CENTER_IMG_URL}/${selectedItem?.image}`}
                            className="img-fluid img1"
                            alt="Your Image"
                          />
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                        <td>Info : </td>
                        <td>4% addition rate in ABS-T </td>
                      </tr> */}
                  </table>
                  <p>
                    The onscreen shades are not necessarily precise
                    representations of the actual colors of the finished article
                    , due to the variance of the monitor calibration and the
                    influence of the form of the final item ( resin used surface
                    gloss, thickness etc ). For a sample of any existing color
                    shade or an accurate match , a sample of masterbatch pellet
                    and/or of the final product is required.
                  </p>
                </div>
              </div>
              <button onClick={handleClose}>
                <img src={Images.Close} />
              </button>
            </Modal.Body>
          </Modal>
        )}
      </div>
      <Footer />
    </>
  );
};
export default ExperienceCenter;
