import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import Images from "../../../helpers/images";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import { isMobile } from "react-device-detect";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const TechnicalServicesSupport = () => {
  const { width } = useWindowDimensions();

  // for lang change
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <>
      <CommonMeta
        title="Poddar Pigments Ltd: Advanced Testing Facilities and Equipment"
        description="Discover PPL's unparalleled technical support with cutting-edge test facilities and expert guidance. Partner with us for top-notch quality, agility, timely delivery, customization, and an extensive color library."
        keywords="masterbatch india, masterbatches manufacturer in india, colour masterbatch"
        name="Technical Support"
        type="article"
      />
      <Header />
      <div className="top-banner-image technical-support-services-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1>{t("Technical Support")} </h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/r-and-d-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("R & D Support")}</h4>
                      ) : (
                        <>
                          <h4>{t("R & D Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/marketing-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>  {t("Marketing Support")}</h4>
                      ) : (
                        <>
                          <h4> {t("Marketing Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/experience-center">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Experience Center")}</h4>
                      ) : (
                        <>
                          <h4>{t("Experience Center")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/digital-shade-card">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("Digital Shade Card")}</h4>
                      ) : (
                        <>
                          <h4>{t("Digital Shade Card")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Technical Support")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-desc-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <p>{t("At Poddar Pigments Limited (PPL), our commitment to your success extends beyond delivering exceptional products. We believe in going the extra mile by providing comprehensive technical support. Our responsive team of experts is always at your service, ready to assist you with any technical queries or challenges you may encounter.")}</p>
              </div>
            </Col>

            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.tss1}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.tss2}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
            <Col lg={6}>
              <h5>{t("Cutting-Edge Test Facilities")}</h5>
              <ul>
                <li><strong> {t("Color Matching Instruments :")} </strong>
                  {t("Precise color matching capabilities.")}
                </li>
                <li><strong>{t("IV Tester :")} </strong>
                  {t("Advanced Intrinsic Viscosity testing.")}
                </li>
                <li> <strong>{t("TGA (Thermogravimetric Analysis) :")} </strong> {t("Analyzing thermal properties.")}</li>
                <li><strong>{t("DSC (Differential Scanning Calorimetry) : ")} </strong> {t("Investigating heat flow.")}</li>
                <li><strong> {t("FTIR (Fourier Transform Infrared Spectroscopy) : ")} </strong>{t("Detailed chemical analysis.")} </li>
                <li><strong> {t("Filter Index Tester :")}</strong> {t("Evaluating filtration properties.")}</li>
                <li><strong> {t("MFI (Melt Flow Index) Tester : ")}</strong>{t("Assessing melt properties.")} </li>
                <li><strong>{t("Blown Film Line : ")}  </strong>{t("Testing film production.")} </li>
              </ul>
            </Col>
            <Col lg={12}>
              <div className="" style={{ marginTop: 30 }}>
                <h5> {t("Collaborative Approach")}</h5>
                <ul>
                  <li><strong>{t("Understanding Your Needs : ")}</strong>
                  {t("We work closely with clients to comprehend specific requirements.")}

                  </li>
                  <li><strong>{t("Custom Solutions :")} </strong>
                  {t("We provide tailored responses to address unique challenges.")} 
                    
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="technical-banner">
                <img
                  loading="lazy"
                  src={Images.tecBanner}
                  className="img-fluid img1"
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6}>
              <div>
                <h5>{t("Unwavering Dedication to Excellence")} </h5>
                <ul>
                  <li><strong> {t("Quality Assurance : ")}</strong>
                  {t("We do thorough testing and analysis for superior quality.")}
                  </li>
                  <li><strong> {t("Performance Commitment :")} </strong>
                  {t("We ensure our products meet the highest industry standards.")}
                  </li>
                </ul>
                <h5> {t("Empowering Your Success")}</h5>
                <ul>
                  <li><strong> {t("Top-Notch Quality :")} </strong>
                    {t("Consistently exceptional products.")}
                  </li>
                  <li><strong> {t("Agility :")} </strong>
                    {t("Prompt and flexible services.")}
                  </li>
                  <li><strong> {t("Timely Delivery :")} </strong>
                     {t("Meeting your deadlines.")}
                  </li>
                  <li><strong> {t("Customization :")} </strong>
                     {t("Tailoring solutions to your needs.")}
                  </li>
                  <li><strong>{t("Extensive Color Library :")} </strong>
                     {t("Access to a wide spectrum of colors.")}
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.tss5}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.tss4}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default TechnicalServicesSupport;
