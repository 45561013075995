import React, { useEffect } from "react";
import config from "../../../config";
import Images from "../../../helpers/images";
import { Image } from "react-bootstrap";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { GlassMagnifier } from "react-image-magnifiers";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const Book = ({ selectedDigitalShade, handleClose }) => {
  const [originalImages, setOriginalImages] = useState([
    ...selectedDigitalShade?.images,
  ]);
  const [images, setImages] = useState([]);
  const [imagesLength, setImagesLength] = useState(3);

  const addPages = (i) => {
    setImagesLength(imagesLength + 2);
  };

  useEffect(() => {
    const items = [...originalImages];
    const newImages = items.slice(0, -(originalImages.length - imagesLength));
    setImages(originalImages.length > imagesLength ? newImages : items);
  }, [imagesLength, originalImages]);

  useEffect(() => {
    const pages = document.getElementsByClassName("page");
    for (let i = 0; i < pages.length; i++) {
      pages[i].pageNum = i + 1;
      pages[i].addEventListener("click", function () {
        if (this.pageNum % 2 === 0) {
          this.classList.remove("flipped");
          const prevSibling = this.previousElementSibling;
          if (prevSibling) {
            prevSibling.classList.remove("flipped");
          }
        } else {
          const nextSibling = this.nextElementSibling;
          if (nextSibling.classList.contains("page")) {
            this.classList.add("flipped");
            if (nextSibling) {
              nextSibling.classList.add("flipped");
            }
          }
        }
      });
    }
  }, [images]);

  return (
    <>
      {/* <div className="book-left-arrow">
        <img
          loading="lazy"
          src={Images.arrowRight}
          className="img-fluid img1"
          alt=""
        />
      </div> */}
      <div className="book">
        <div id="pages" className={`pages ${selectedDigitalShade.bookSize}`}>
          {images?.map((item, i) => (
            <div
              className="page"
              key={`pages${i}`}
              number={i}
              onClick={() => addPages(i)}
              style={{ zIndex: i % 2 === 0 ? originalImages.length - i : "" }}
            >
              <LazyLoadComponent>
                {isMobile ? (
                  <Image
                    loading="lazy"
                    src={`${config.DIGITAL_SHDAE_CARD_IMG_URL}/${item.filename}`}
                    className="img-fluid img1"
                    alt="Your Image"
                  />
                ) : (
                  <GlassMagnifier
                    loading="lazy"
                    allowOverflow={true}
                    magnifierSize={"30%"}
                    zoomLevel={0.3}
                    magnifierBorderSize={1}
                    magnifierBorderColor="#000"
                    magnifierBackgroundColor="#fff"
                    imageSrc={`${config.DIGITAL_SHDAE_CARD_IMG_URL}/${item.filename}`}
                    largeImageSrc={`${config.DIGITAL_SHDAE_CARD_IMG_URL}/${item.filename}`}
                    imageAlt=""
                  />
                )}
              </LazyLoadComponent>
            </div>
          ))}
          <button onClick={handleClose}>
            <Image src={Images.Close} />
          </button>
        </div>
      </div>
      {/* <div className="book-right-arrow">
        <img
          loading="lazy"
          src={Images.arrowRight}
          className="img-fluid img1"
          alt=""
        />
      </div> */}
    </>
  );
};

export default Book;
