import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Chat from "../pages/chatBot";
import { useTranslation } from "react-i18next";


const ContactUs = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const handleChatClick = () => {
    setIsChatOpen(!isChatOpen);
  };
    // for lang change
    const { t, i18n } = useTranslation();

    const changeLanguage = (event) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    };
  return (
    <div className="contact-us-section" id="ContactUs">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="contact-us">
              <h4>{t("homeContact.title")}</h4>
              <div className="title">{t("homeContact.subTitle1")} <br /> {t("homeContact.subTitle2")} <br/>{t("homeContact.subTitle3")}</div>
              <div className="link-btn"><a className="lets-start animated-button" href={void(0)} onClick={handleChatClick} >{t("homeContact.btn")}</a></div>
            </div>
            {isChatOpen && <Chat
              setIsChatOpen={handleChatClick}
            />}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default ContactUs