import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {

    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TelegramShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon

} from "react-share";
import { useToasts } from 'react-toast-notifications';



const Share = ({ share, shareUrl, title }) => {

    const { addToast } = useToasts();
    const handleCopy = () => {
        addToast('Link has been copied', { appearance: 'success' })
    }

    return (
        <>
            <div className='social_share'>
                <div className="Demo__container">
                    <div className="social-network">
                        <div className='copy-btn-custom  social-network__share-button'>
                            <CopyToClipboard text={shareUrl}
                                onCopy={handleCopy}>
                                <span><i className="fa fa-copy "></i></span>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="social-network">
                        <WhatsappShareButton
                            url={shareUrl}

                            className="social-network__share-button"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </div>
                    <div className="social-network">
                        <EmailShareButton url={shareUrl} className="social-network__share-button">
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </div>
                    <div className="social-network">
                        <TelegramShareButton url={shareUrl} className="social-network__share-button">
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </div>
                    <div className="social-network">
                        <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            className="social-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </div>


                </div>
            </div>
        </>
    )

}
export default Share;