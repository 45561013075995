import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../../../helpers/images";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };
  return (
    <div className="home-services-section" id="Services">
      <Container>
        <Row>
          <Col>
            <div className="heading-text">
              <h4> {t("serviceSection.title")}</h4>
              <p>
                {t("serviceSection.desc")}
                <span>
                  <Link to="/services">{t("serviceSection.readMore")}</Link>
                </span>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="">
          <div className="home-services-section-wrapper mobile-wrapper">
            <div className="card-box-wrapper">
              <Link to="/r-and-d-support">
                <div className="overlay"></div>
                <div className="services-images">
                  <img
                    loading="lazy"
                    src={Images.rdSupport}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="card-body">
                  <div className="title">{t("serviceSection.r&d.title")}</div>
                  <p>{t("serviceSection.r&d.desc")}</p>
                  <div className="card-bottom-text">
                    <div className="link-text">
                      <Link to="/r-and-d-support">
                        {t("serviceSection.r&d.more")} {">"}
                      </Link>
                    </div>
                    {/* <div className="number">01.</div> */}
                  </div>
                </div>
              </Link>
            </div>

            <div className="card-box-wrapper">
              <Link to="/technical-services-support">
                <div className="overlay"></div>
                <div className="services-images">
                  <img
                    loading="lazy"
                    src={Images.technical}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="card-body">
                  <div className="title">{t("serviceSection.technical.title")}</div>
                  <p>
                  {t("serviceSection.technical.desc")} <br />
                    <br />
                  </p>
                  <div className="card-bottom-text">
                    <div className="link-text">
                      <Link to="/technical-services-support">
                      {t("serviceSection.technical.more")} {">"}
                      </Link>
                    </div>
                    {/* <div className="number">02.</div> */}
                  </div>
                </div>
              </Link>
            </div>

            <div className="card-box-wrapper">
              <Link to="/marketing-support">
                <div className="overlay"></div>
                <div className="services-images">
                  <img
                    loading="lazy"
                    src={Images.marketing}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="card-body">
                  <div className="title">{t("serviceSection.marketing.title")}</div>
                  <p>{t("serviceSection.marketing.desc")}</p>
                  <div className="card-bottom-text">
                    <div className="link-text">
                      <Link to="/marketing-support">
                        {t("serviceSection.marketing.more")} {">"}
                      </Link>
                    </div>
                    {/* <div className="number">03.</div> */}
                  </div>
                </div>
              </Link>
            </div>

            <div className="card-box-wrapper">
              <Link to="/experience-center">
                <div className="overlay"></div>
                <div className="services-images">
                  <img
                    loading="lazy"
                    src={Images.experienceCenter}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="card-body">
                  <div className="title">{t("serviceSection.experience.title")}</div>
                  <p>{t("serviceSection.experience.desc")}</p>
                  <div className="card-bottom-text">
                    <div className="link-text">
                      <Link to="/experience">
                        {t("serviceSection.experience.more")} {">"}
                      </Link>
                    </div>
                    {/* <div className="number">04.</div> */}
                  </div>
                </div>
              </Link>
            </div>

            <div className="card-box-wrapper">
              <Link to="/digital-shade-card">
                <div className="overlay"></div>
                <div className="services-images">
                  <img
                    loading="lazy"
                    src={Images.digitalHome}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="card-body">
                  <div className="title">{t("serviceSection.digital.title")}</div>
                  <p>{t("serviceSection.digital.desc")}</p>
                  <div className="card-bottom-text">
                    <div className="link-text">
                      <Link to="/digital-shade-card">
                      {t("serviceSection.digital.more")}{">"}
                      </Link>
                    </div>
                    {/* <div className="number">04.</div> */}
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default Services;
