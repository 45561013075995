import { Col, Container, Modal, Row } from "react-bootstrap";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Share from "../shareIt";
import config from "../../../config";
import { digitalShadeCardList as digitalShadeCardListApi } from "./../../../helpers/api";
import Book from "./book";
import InlineLoader from "../../comman/InlineLoader";
import { isMobile } from "react-device-detect";
import Images from "../../../helpers/images";
import CommonMeta from "../../comman/CommonMeta";
import { useTranslation } from "react-i18next";

const DigitalShadeCard = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const shadeId = searchParams.get("pId") ? searchParams.get("pId") : "";
  const [loading, setLoading] = useState(true);
  const [digitalShadeDataFibre, setDigitalShadeDataFibre] = useState([]);
  const [digitalShadeDataPlastics, setDigitalShadeDataPlastics] = useState([]);
  const [digitalShadeDataProductFlyer, setDigitalShadeDataProductFlyer] =
    useState([]);
  const [selectedDigitalShade, setSelectedDigitalShade] = useState();
  const [title, setTitle] = useState("");

  const [currentShadeCard, setCurrentShadeCard] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (item) => {
    setSelectedDigitalShade(item);
    setShow(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const dataFibre = await digitalShadeCardListApi("ManMadeFiber");
      const dataPlastics = await digitalShadeCardListApi("Plastics");
      const dataProductFlyer = await digitalShadeCardListApi("ProductFlyer");

      setDigitalShadeDataFibre(dataFibre.data.result);
      setDigitalShadeDataPlastics(dataPlastics.data.result);
      setDigitalShadeDataProductFlyer(dataProductFlyer.data.result);
      if (shadeId != '') {
        const selectedDataFiber = dataFibre.data.result.find(item => item.id == shadeId);
        const selecteddataPlastics = dataPlastics.data.result.find(item => item.id == shadeId);
        const selecteddataProductFlers = dataProductFlyer.data.result.find(item => item.id == shadeId);
        if (selectedDataFiber != undefined) {
          setSelectedDigitalShade(selectedDataFiber);
          setShow(true);
        }
        if (selecteddataPlastics != undefined) {
          setSelectedDigitalShade(selecteddataPlastics);
          setShow(true);
        }
        if (selecteddataProductFlers != undefined) {
          setSelectedDigitalShade(selecteddataProductFlers);
          setShow(true);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleShare = (item) => {
    setTitle(item.title);
    setCurrentShadeCard(
      currentShadeCard && currentShadeCard == item.id ? null : item.id
    );
  };

  const copy = (index, type) => {
    const data =
      type === "Fiber"
        ? [...digitalShadeDataFibre]
        : type === "Platics"
        ? [...digitalShadeDataPlastics]
        : [...digitalShadeDataProductFlyer];
    data[index]["copied"] = true;
    type === "Fiber"
      ? setDigitalShadeDataFibre(data)
      : type === "Platics"
      ? setDigitalShadeDataPlastics(data)
      : setDigitalShadeDataProductFlyer(data);

    setTimeout(() => {
      const data =
        type === "Fiber"
          ? [...digitalShadeDataFibre]
          : type === "Platics"
          ? [...digitalShadeDataPlastics]
          : [...digitalShadeDataProductFlyer];
      data[index]["copied"] = false;
      type === "Fiber"
        ? setDigitalShadeDataFibre(data)
        : type === "Platics"
        ? setDigitalShadeDataPlastics(data)
        : setDigitalShadeDataProductFlyer(data);
    }, 1000);
  };

  // for lang change
  const { t } = useTranslation();

  return (
    <>
      <CommonMeta
        title="Digital Shade Card for Plastic Products - Poddar Pigments Ltd"
        description="Experience the convenience of selecting plastic and man-made fiber shades with PPL's Digital Shade Card. Say goodbye to physical limitations and find your ideal shade effortlessly."
        keywords="plastic masterbatch manufacturer, masterbatch company in Jaipur, color masterbatch manufacturer"
        name="Digital Shade Card"
        type="article"
      />
      <Header />
      {/* <div  className="digital-mobile-img top-banner-image"></div> */}
      <div className="top-banner-image digital-shade-services-bg">
        <Container>
          <Row>
            <div className="top-heading-text">
              <h1> {t("Digital Shade Card")}</h1>
            </div>
          </Row>
          <Row>
            <div className="services-link-box-wrapper">
              <div className="card-box-wrapper">
                <Link to="/r-and-d-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4>{t("R & D Support")}</h4>
                      ) : (
                        <>
                          <h4>{t("R & D Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/technical-services-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Technical Support")}</h4>
                      ) : (
                        <>
                          <h4> {t("Technical Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/marketing-support">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Marketing Support")}</h4>
                      ) : (
                        <>
                          <h4> {t("Marketing Support")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
              <div className="card-box-wrapper">
                <Link to="/experience-center">
                  <div className="card-box">
                    <div className="name">
                      {isMobile ? (
                        <h4> {t("Experience Center")}</h4>
                      ) : (
                        <>
                          <h4> {t("Experience Center")}</h4>
                          <div className="icon">
                            <i className="icon-next_arrow"></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="breadcrumb-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Digital Shade Card")}
                  </li>
                </ol>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-desc-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                {/* <h2>Digital Shade Card</h2> */}
                <p>
                  {" "}
                  {t(
                    "Poddar Pigments Limited (PPL) is excited to introduce our Digital Shade Card section which is, designed exclusively for our plastic and man-made fiber product ranges. With this innovative tool, choosing the perfect shade has become incredibly easy. Bid farewell to the constraints of physical shade cards and embrace the convenience of digital selection."
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <Row className=" desc-box-wrap mb-5 mobile-order digital-des">
            <Col lg={6}>
              <h5>{t("Plastics Products")}</h5>
              <ul>
                <li>
                  <strong> {t("Effortless Exploration :")} </strong>
                  {t(
                    "Browse a diverse spectrum of plastic shades with a few clicks."
                  )}
                </li>
                <li>
                  <strong> {t("Visual Inspection :")} </strong>
                  {t(
                    "Examine each shade digitally to ensure it meets your requirements."
                  )}
                </li>
                <li>
                  <strong> {t("Precision Selection :")} </strong>
                  {t(
                    "Choose the plastic shade that aligns perfectly with your needs."
                  )}
                </li>
              </ul>
            </Col>
            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.digitalPlastics1}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.digitalPlastics2}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
          </Row>

          <InlineLoader loading={loading} />
          {!loading && (
            <div className="table-responsive digital-main-wrapper">
              {digitalShadeDataPlastics.length > 0 && (
                <table>
                  <tbody>
                    {digitalShadeDataPlastics?.map((item, i) => (
                      <tr key={`digitalShadeDataPlastics_${i}`}>
                        <td className="title-wrapper">
                          <div>
                            <h6>{t(`${item.title}`)}</h6>
                          </div>
                        </td>
                        <td>
                          <div className="digital-card-btn digital-card-btn-wrapper">
                            <a
                              onClick={() => handleShow(item)}
                              className="digital-btn animated-button"
                            >
                              {t("View")} <i className="icon-view"></i>
                            </a>
                            <div className="share-button-wrapper">
                              <a
                                className="digital-btn animated-button"
                                onClick={(e) => {
                                  handleShare(item);
                                }}
                              >
                                {t("Share")} <i className="icon-share"></i>
                              </a>
                              {currentShadeCard === item.id && (
                                <Share
                                  shareUrl={`${window.location.href}?pId=${item.id}`}
                                  title={title}
                                />
                              )}
                            </div>
                            <CopyToClipboard
                              onCopy={() => copy(i, "Platics")}
                              text={`${window.location.href}?pId=${item.id}`}
                            >
                              <a className="digital-btn animated-button">
                                {item.copied ? (
                                  <span>{t("Copied")}</span>
                                ) : (
                                  <span>{t("Copy Link")}</span>
                                )}
                                <i className="icon-copy"></i>
                              </a>
                            </CopyToClipboard>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
          <Row className=" digital-card-bottom-box desc-box-wrap mb-5 mobile-order digital-des">
            <Col lg={6}>
              <h5> {t("Man-Made Fiber Products")} </h5>
              <ul>
                <li>
                  <strong> {t("Extensive Array :")} </strong>
                  {t(
                    "Explore a wide array of man-made fiber shades in digital format."
                  )}
                </li>
                <li>
                  <strong> {t("Efficient Comparison :")} </strong>
                  {t(
                    "Easily compare different options to identify the ideal shade."
                  )}
                </li>
                <li>
                  <strong> {t("Streamlined Selection :")} </strong>
                  {t(
                    "Simplify the man-made fiber color selection process for accurate matches."
                  )}
                </li>
              </ul>
            </Col>
            <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.digitalFiber1}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.digitalFiber2}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <InlineLoader loading={loading} />
          {!loading && (
            <div className="table-responsive digital-main-wrapper">
              {digitalShadeDataFibre.length > 0 && (
                <table>
                  <tbody>
                    {digitalShadeDataFibre?.map((item, j) => (
                      <tr key={`digitalShadeDataFibre_${j}`}>
                        <td className="title-wrapper">
                          <div>
                            <h6>{t(`${item.title}`)}</h6>
                          </div>
                        </td>
                        <td>
                          <div className="digital-card-btn digital-card-btn-wrapper">
                            <a
                              onClick={() => handleShow(item)}
                              className="digital-btn animated-button"
                            >
                              {t("View")} <i className="icon-view"></i>
                            </a>
                            <div className="share-button-wrapper">
                              <a
                                className="digital-btn animated-button"
                                onClick={(e) => {
                                  handleShare(item);
                                }}
                              >
                                {t("Share")} <i className="icon-share"></i>
                              </a>
                              {currentShadeCard === item.id && (
                                <Share
                                  shareUrl={`${window.location.href}?pId=${item.id}`}
                                  title={title}
                                />
                              )}
                            </div>
                            <CopyToClipboard
                              onCopy={() => copy(j, "Fiber")}
                              text={`${window.location.href}?pId=${item.id}`}
                            >
                              <a className="digital-btn animated-button">
                                {item.copied ? (
                                  <span>{t("Copied")}</span>
                                ) : (
                                  <span>{t("Copy Link")}</span>
                                )}
                                <i className="icon-copy"></i>
                              </a>
                            </CopyToClipboard>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
          <Row className=" digital-card-bottom-box desc-box-wrap mb-5 mobile-order digital-des">
            <Col lg={12}>

              <h5> {t("Product Flyers")} </h5>
              {/* <ul>
                <li><strong>  {t("Extensive Array :")} </strong>
                  {t("Explore a wide array of man-made fiber shades in digital format.")}
                </li>
                <li>
                  <strong> {t("Efficient Comparison :")} </strong>
                  {t(
                    "Easily compare different options to identify the ideal shade."
                  )}
                </li>
                <li>
                  <strong> {t("Streamlined Selection :")} </strong>
                  {t(
                    "Simplify the man-made fiber color selection process for accurate matches."
                  )}
                </li>
              </ul> */}
            </Col>
            {/* <Col lg={6}>
              <div className="image-box">
                <img
                  loading="lazy"
                  src={Images.digitalFiber1}
                  className="img-fluid img1"
                  alt=""
                />
                <img
                  loading="lazy"
                  src={Images.digitalFiber2}
                  className="img-fluid img2"
                  alt=""
                />
              </div>
            </Col> */}
          </Row>
          <InlineLoader loading={loading} />
          {!loading && (
            <div className="table-responsive digital-main-wrapper">
              {digitalShadeDataProductFlyer.length > 0 && (
                <table>
                  <tbody>
                    {digitalShadeDataProductFlyer?.map((item, j) => (
                      <tr key={`digitalShadeDataProductFlyer_${j}`}>
                        <td className="title-wrapper">
                          <div>
                            <h6>{t(`${item.title}`)}</h6>
                          </div>
                        </td>
                        <td>
                          <div className="digital-card-btn digital-card-btn-wrapper">
                            <a
                              onClick={() => handleShow(item)}
                              className="digital-btn animated-button"
                            >
                              {t("View")} <i className="icon-view"></i>
                            </a>
                            <div className="share-button-wrapper">
                              <a
                                className="digital-btn animated-button"
                                onClick={(e) => {
                                  handleShare(item);
                                }}
                              >
                                {t("Share")} <i className="icon-share"></i>
                              </a>
                              {currentShadeCard === item.id && (
                                <Share
                                  shareUrl={`${window.location.href}?pId=${item.id}`}
                                  title={title}
                                />
                              )}
                            </div>
                            <CopyToClipboard
                              onCopy={() => copy(j, "Flyers")}
                              text={`${window.location.href}?pId=${item.id}`}
                            >
                              <a className="digital-btn animated-button">
                                {item.copied ? (
                                  <span>{t("Copied")}</span>
                                ) : (
                                  <span>{t("Copy Link")}</span>
                                )}
                                <i className="icon-copy"></i>
                              </a>
                            </CopyToClipboard>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Container>
      </div>
      <Footer />

      <Modal
        centered
        className="book-modal"
        size="xl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="digital-modal">
          <Book
            selectedDigitalShade={selectedDigitalShade}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DigitalShadeCard;
