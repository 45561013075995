import CommonMeta from "../../comman/CommonMeta";
import ContactUs from "../../comman/contactUs";
import Footer from "../../comman/footer";
import Header from "../../comman/header";
import HomeSlider from "../../comman/slider";
import AboutUs from "./aboutUs";
import GlobalPresence from "./globalPresence/globalPresence";
import InvestorRelations from "./investorRelations";
import LaunchModal from "./launchModal";
import Products from "./products";
import Services from "./services";
import Sustainability from "./sustainability";
import Testimonials from "./testimonials";
import WhyChooseUs from "./whyChooseUs";

const Home = () => {
  return (
    <>
      <CommonMeta
        title="Poddar Pigments: Masterbatch Manufacturer & Supplier in India"
        description="Discover high-quality masterbatches at Poddar Pigments, your trusted manufacturer & supplier in India. Elevate your products with our color solutions"
        keywords="masterbatch india, masterbatches manufacturer in india, color masterbatch"
        name="home"
        type="article"
      />
      <Header />
      <HomeSlider />
      <AboutUs />
      <Products />
      <Services />
      <GlobalPresence />
      <Sustainability />
      <InvestorRelations />
      <WhyChooseUs />
      <Testimonials />
      <ContactUs />
      <LaunchModal />
      <Footer />
    </>
  );
};
export default Home;
