import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CommonMeta from '../../comman/CommonMeta';
import Footer from '../../comman/footer';
import Header from '../../comman/header';
import useWindowDimensions from '../../../helpers/useWindowDimensions';

const MasterbatchesForPlasticApplications = () => {
  const { t, i18n } = useTranslation();
  const [activeKey, setActiveKey] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentData, setCurrentData] = useState([]);

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setSelectedDesc(item.desc);
    setActiveItem(item.name);
    setSidebarVisible(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    removeClass();
  };

  const removeClass = () => {
    document.body.classList.remove('bg-overflow-handle');
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleSidebarMenuClick = () => {
    setSidebarVisible(!sidebarVisible);
    scrollToTop();
    document.body.classList.add('bg-overflow-handle');
  };

  useEffect(() => {
    const getSelectedLanguageTranslation = (currentLanguage) => {
      switch (currentLanguage) {
        case 'en':
          return require('.././../../helpers/productLangData/plasticsAppplication/en.json');
        case 'es':
          return require('.././../../helpers/productLangData/plasticsAppplication/es.json');
        case 'de':
          return require('.././../../helpers/productLangData/plasticsAppplication/de.json');
        case 'fr':
          return require('.././../../helpers/productLangData/plasticsAppplication/fr.json');
        case 'pt':
          return require('.././../../helpers/productLangData/plasticsAppplication/pt.json');
        default:
          return require('.././../../helpers/productLangData/plasticsAppplication/en.json');
      }
    };

    const translatedData = getSelectedLanguageTranslation(i18n.language);
    setCategories(translatedData);

    const subcategory = searchParams.get('sc');

    if (subcategory) {
      const subCategoryOutput = translatedData.find(
        (item) => item.name.toLowerCase() === subcategory.toLowerCase()
      );
      if (subCategoryOutput !== undefined) {
        handleItemClick(subCategoryOutput);
        return;
      }
    } else {
      handleItemClick(translatedData[0]);
    }
  }, [i18n.language, searchParams]);

  const { width } = useWindowDimensions();

  return (
    <>
      <Header />
      <div className="product-wrapper-section">
        <Row className="g-0">
          <div className="paroduct-main">
            {width <= 768 && (
              <span
                className="mobile-side-btn"
                onClick={() => handleSidebarMenuClick()}
              >
                {sidebarVisible ? (
                  ''
                ) : (
                  <div className="more-category-btn-wrapper">
                    <button>
                      <i className="theme-icon icon-ham_burger"></i>
                    </button>
                  </div>
                )}
              </span>
            )}
            <div
              className={`masterbatches-for-plastic-applications-cat product-category-filter ${
                sidebarVisible ? 'active' : ''
              }`}
            >
              <div className="category-title">
                <h4>  {t('Masterbatches For Plastic Applications')}</h4>
              </div>
              {categories.map((item, i) => (
                <div key={i}>
                  <Accordion
                    activeKey={activeKey}
                    onSelect={handleAccordionToggle}
                  >
                    <Accordion.Item eventKey={`category-${item.id}`}>
                      <Accordion.Header
                        className={
                          activeItem === item.name
                            ? 'active arrow-remove'
                            : 'arrow-remove'
                        }
                        onClick={() => {
                          handleItemClick(item);
                        }}
                      >
                        {t(`${item.name}`)}
                      </Accordion.Header>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))}
            </div>
            <div className="product-details m-3">
              <div className="product-head-button">
                <Link
                  to="/masterbatch-for-man-made-fiber"
                  className="product-btn animated-button"
                >
                  {t('Explore Masterbatch for Man-Made Fiber')}
                  <i className="icon-next"></i>
                </Link>
                <Link
                  to="/functional-and-additive-masterbatches"
                  className="product-btn animated-button"
                >
                  {t('Explore Functional and Additive Masterbatches')}
                  <i className="icon-next"></i>
                </Link>
              </div>
              <div className="product-details-images-section">
                <div className="product-image">
                  <img
                    width={'100%'}
                    loading="lazy"
                    src={`${selectedItem?.image}`}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className={`${selectedItem?.white_heading ? 'white' : ''} product-title-name`}>
                  {t(`${selectedItem?.name}`)}
                </h3>
              </div>
              <div className="breadcrumb-wrapper">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/"> {t('Home')}</Link>
                          </li>
                          <li className="breadcrumb-item active">   {t('Masterbatches for Plastic Applications')} </li>
                        </ol>
                      </nav>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{ __html: selectedItem?.desc }}
              ></div>
            </div>
          </div>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default MasterbatchesForPlasticApplications;
